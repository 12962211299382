import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "mb-4 font-bold" }

import Room from '@/pages/Room.vue';
	import { buttonsYesNo, DialogButtonAction } from '@/logic/store/dialog';
	import { useUser } from '@/logic/store/user';

	import RoomEvent from '../rooms/RoomEvent.vue';
	import Dialog from '../ui/Dialog.vue';
	import { MsgType } from 'matrix-js-sdk';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteMessageDialog',
  props: {
		event: {
			type: Object,
			required: true,
		},
		viewFromThread: {
			type: Boolean,
			default: false,
		},
		room: {
			type: Room,
			required: true,
		},
	},
  emits: ['yes', 'close'],
  setup(__props, { emit: __emit }) {

	const user = useUser();

	// Components
	const emit = __emit;

	const props = __props;

	async function close(returnValue: DialogButtonAction) {
		if (returnValue === 1) {
			emit('yes');
		}
		emit('close');
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    title: _ctx.$t('message.delete.heading'),
    buttons: _unref(buttonsYesNo),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (close($event))),
    width: "max-w-full lg:max-w-[40%] min-w-[92.5%] lg:min-w-[22.5%]"
  }, {
    default: _withCtx(() => [
      (!_unref(user).isAdmin && (__props.event.content.msgtype === _unref(MsgType).File || __props.event.content.msgtype === _unref(MsgType).Image))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('message.delete.beware')), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('message.delete.file_not_deleted')), 1)
          ]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_Suspense, null, {
        fallback: _withCtx(() => [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('state.loading_message')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(RoomEvent, {
            class: "w-fit",
            event: __props.event,
            room: __props.room,
            deleteMessageDialog: true,
            viewFromThread: props.viewFromThread
          }, null, 8, ["event", "room", "viewFromThread"])
        ]),
        _: 1
      }))
    ]),
    _: 1
  }, 8, ["title", "buttons"]))
}
}

})