import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red"
}

import { ValidationErrors } from '@/logic/composables/useFormState';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ValidationErrors',
  props: {
    errors: {}
  },
  setup(__props: any) {

	

return (_ctx: any,_cache: any) => {
  return (Object.keys(_ctx.errors).length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(_ctx.$t(error.error, [error.field, error.param])), 1))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})