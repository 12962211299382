import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-4 flex flex-col items-center md:flex-row md:items-start" }
const _hoisted_2 = { class: "text-gray font-semibold md:w-2/6" }
const _hoisted_3 = { class: "flex flex-col justify-between md:w-4/6 md:flex-row" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mt-5 flex justify-center md:mr-3 md:flex-col md:justify-normal md:space-y-4" }
const _hoisted_6 = { for: "avatar" }
const _hoisted_7 = { class: "mb-4 flex flex-col md:flex-row" }
const _hoisted_8 = { class: "text-gray w-2/6 font-semibold" }
const _hoisted_9 = { class: "mb-4 flex flex-col md:flex-row" }
const _hoisted_10 = { class: "text-gray w-2/6 font-semibold" }
const _hoisted_11 = {
  title: "Hub specific User ID",
  class: "p-1 text-lg italic text-on-surface-dim ~text-base-min/base-max md:w-4/6"
}
const _hoisted_12 = {
  key: 0,
  class: "bg-green-dark mt-2 rounded-lg p-2 text-white"
}

import { fileUpload } from '@/logic/composables/fileUpload';
	import { FormDataType, useFormState } from '@/logic/composables/useFormState';
	import { useMatrixFiles } from '@/logic/composables/useMatrixFiles';
	import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { useSettings } from '@/logic/store/settings';
	import { buttonsSubmitCancel, DialogButtonAction, DialogSubmit, useDialog } from '@/logic/store/store';
	import { useUser } from '@/logic/store/user';
	import { onMounted, ref } from 'vue';
	import { useI18n } from 'vue-i18n';

	// Components
	import Avatar from '../ui/Avatar.vue';
	import Dialog from '../ui/Dialog.vue';
	import Icon from '../elements/Icon.vue';
	import TextInput from './TextInput.vue';
	import ValidationErrors from './ValidationErrors.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsDialog',
  setup(__props) {

	const { t } = useI18n();
	const user = useUser();
	const settings = useSettings();
	const dialog = useDialog();
	const formState = useFormState();
	const pubhubs = usePubHubs();
	const { imageTypes, uploadUrl } = useMatrixFiles();
	const fileInfo = ref<File>();
	const avatarRemoved = ref<boolean>(false);

	let avatarMxcUrl = ref<string | undefined>(undefined);
	let blobUrl = ref<string | undefined>(undefined);

	formState.setData({
		displayName: {
			value: user.user.displayName as string,
			validation: { required: true, max_length: settings.getDisplayNameMaxLength, allow_empty_number: false, allow_empty_object: false, allow_empty_text: true },
			show_validation: { required: false, max_length: true },
		},
	});

	onMounted(() => {
		formState.setSubmitButton(getSubmitButton());
		formState.data.displayName.value = user.user.displayName as FormDataType;
		blobUrl.value = user.avatarUrl;
		avatarRemoved.value = false;
	});

	function dialogAction(action: DialogButtonAction) {
		if (action === DialogSubmit) {
			submit();
		}
	}

	function getSubmitButton() {
		return dialog.properties.buttons[0];
	}

	async function submit() {
		// This check enables empty values to be submitted since dataIsChanged() method can't handle empty values conditional cal.
		if (formState.dataIsChanged('displayName')) {
			const newDisplayName = formState.data.displayName.value as string;
			await pubhubs.changeDisplayName(newDisplayName);
			formState.setMessage(t('settings.displayname_changed', [newDisplayName]));
			formState.updateData('displayName', newDisplayName);
		}
		if (fileInfo.value) {
			await uploadAvatar();
		}
		if (avatarMxcUrl.value !== undefined) {
			user.setAvatarMxcUrl(avatarMxcUrl.value, true);
		}
		if (blobUrl.value) {
			URL.revokeObjectURL(blobUrl.value);
		}
	}

	async function chooseAvatar(event: Event) {
		getSubmitButton().enabled = true;
		const target = event.currentTarget as HTMLInputElement;
		const file = target.files && target.files[0];
		if (file) {
			fileInfo.value = file;
			blobUrl.value = URL.createObjectURL(file);
		}
	}
	// Avatar related functions
	async function uploadAvatar() {
		const accessToken = pubhubs.Auth.getAccessToken();
		const syntheticEvent = {
			currentTarget: {
				files: [fileInfo.value],
			},
		} as unknown as Event;
		if (accessToken) {
			const errorMsg = t('errors.file_upload');
			fileUpload(errorMsg, accessToken, uploadUrl, imageTypes, syntheticEvent, (mxUrl) => {
				avatarMxcUrl.value = mxUrl;
				if (avatarMxcUrl.value !== undefined) {
					user.setAvatarMxcUrl(avatarMxcUrl.value, true);
				}
			});
		} else {
			console.error('Access Token is invalid for File upload.');
		}
	}

	async function removeAvatar() {
		avatarMxcUrl.value = '';
		if (blobUrl.value) {
			URL.revokeObjectURL(blobUrl.value);
		}
		blobUrl.value = undefined;
		fileInfo.value = undefined;
		avatarRemoved.value = true;
		getSubmitButton().enabled = true;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    title: _ctx.$t('settings.title'),
    buttons: _unref(buttonsSubmitCancel),
    onClose: _cache[4] || (_cache[4] = ($event: any) => (dialogAction($event)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('settings.avatar')), 1),
          _createElementVNode("input", {
            type: "file",
            id: "avatar",
            accept: "image/png, image/jpeg, image/svg",
            class: "hidden",
            ref: "file",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (chooseAvatar($event)))
          }, null, 544),
          _createElementVNode("div", _hoisted_3, [
            (_unref(blobUrl))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _unref(blobUrl),
                  class: "h-32 w-32 rounded-full"
                }, null, 8, _hoisted_4))
              : (avatarRemoved.value)
                ? (_openBlock(), _createBlock(Avatar, {
                    key: 1,
                    user: _unref(user).user,
                    "override-avatar-url": "",
                    class: "h-32 w-32 rounded-full"
                  }, null, 8, ["user"]))
                : (_openBlock(), _createBlock(Avatar, {
                    key: 2,
                    user: _unref(user).user,
                    "override-avatar-url": _unref(blobUrl),
                    class: "h-32 w-32 rounded-full"
                  }, null, 8, ["user", "override-avatar-url"])),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, [
                _createVNode(Icon, {
                  size: "lg",
                  type: "edit",
                  class: "cursor-pointer hover:text-on-surface-variant group-hover:block"
                })
              ]),
              _createVNode(Icon, {
                size: "lg",
                type: "bin",
                class: "cursor-pointer hover:text-on-surface-variant group-hover:block",
                onClick: removeAvatar
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('settings.displayname')), 1),
          _createVNode(TextInput, {
            class: "rounded border p-1 ~text-base-min/base-max focus:border-blue-500 focus:outline-none md:w-4/6",
            name: "displayname",
            modelValue: _unref(formState).data.displayName.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formState).data.displayName.value) = $event)),
            modelModifiers: { trim: true },
            placeholder: _ctx.$t('settings.displayname'),
            onChanged: _cache[2] || (_cache[2] = ($event: any) => (_unref(formState).updateData('displayName', $event)))
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('settings.userId')), 1),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(user).user.userId), 1)
        ])
      ], 32),
      _createVNode(ValidationErrors, {
        errors: _unref(formState).validationErrors.value
      }, null, 8, ["errors"]),
      (_unref(formState).message.value !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_unref(formState).message), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "buttons"]))
}
}

})