import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

import Dialog from '../ui/Dialog.vue';
	import FormLine from '../forms/FormLine.vue';
	import TextInput from '../forms/TextInput.vue';
	import Label from '../forms/Label.vue';
	import FormObjectInput from '../forms/FormObjectInput.vue';

	import { FormObjectInputTemplate } from '@/logic/composables/useFormInputEvents';
	import { useFormState } from '@/logic/composables/useFormState';
	import { isEmpty, trimSplit } from '@/logic/core/extensions';
	import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { buttonsSubmitCancel, DialogButtonAction } from '@/logic/store/dialog';
	import { RoomType } from '@/logic/store/rooms';
	import { SecuredRoomAttributes, TSecuredRoom, useRooms } from '@/logic/store/store';
	import Room from '@/model/rooms/Room';

	import { useYivi } from '@/logic/store/yivi';
	import { computed, onBeforeMount, ref } from 'vue';
	import { useI18n } from 'vue-i18n';

	interface SecuredRoomAttributesObject {
		yivi_attribute: string;
		accepted_values: Array<string>;
		profile: boolean;
	}

	
export default /*@__PURE__*/_defineComponent({
  __name: 'EditRoomForm',
  props: {
		room: {
			type: Object,
			default: ref({} as unknown as TSecuredRoom | Room),
		},
		secured: {
			type: Boolean,
			default: false,
		},
	},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	// Components
	const { t } = useI18n();
	const { setData, updateData } = useFormState();
	const pubhubs = usePubHubs();
	const rooms = useRooms();

	//const dialog = useDialog();
	const yivi = useYivi();
	const emit = __emit;

	const props = __props;

	let errorMessage = ref<string | undefined>(undefined);

	// used to check if no attribute values have been deleted after editing
	let previousAccepted: SecuredRoomAttributes;

	//#region Computed properties

	const isNewRoom = computed(() => {
		return isEmpty(props.room);
	});

	const title = computed(() => {
		if (isNewRoom.value) {
			if (props.secured) {
				return t('admin.add_secured_room');
			} else {
				return t('admin.add_room');
			}
		}
		if (props.secured) {
			return t('admin.edit_secured_room');
		}
		return t('admin.edit_name');
	});

	//#region types & defaults

	const emptyNewRoom = {
		name: '',
		topic: '',
		accepted: [] as Array<SecuredRoomAttributesObject>,
		user_txt: '',
		type: '',
	} as TSecuredRoom;

	const editRoom = ref({} as Room | TSecuredRoom);

	const securedRoomTemplate = ref([
		{ key: 'yivi', label: t('admin.secured_attribute'), type: 'autocomplete', options: [], default: '', disabled: !isNewRoom.value },
		{ key: 'values', label: t('admin.secured_values'), type: 'textarea', default: '', maxLength: 3000 },
		{ key: 'profile', label: t('admin.secured_profile'), type: 'checkbox', default: false },
	] as Array<FormObjectInputTemplate>);

	//#region mount
	onBeforeMount(async () => {
		//Fetch the attrbiute objects
		securedRoomTemplate.value[0].options = yivi.attributesOptions;
		securedRoomTemplate.value[0].options.forEach((option) => {
			option.label = t(option.label);
		});

		if (isNewRoom.value) {
			// New Room
			editRoom.value = { ...emptyNewRoom } as Room | TSecuredRoom;
		} else {
			// Edit room

			editRoom.value = { ...(props.room as Room | TSecuredRoom) };
			editRoom.value.topic = rooms.getRoomTopic(props.room.room_id);
			// Transform for form
			if (props.secured) {
				// Remove 'profile' for existing secured room (cant be edited after creation)
				securedRoomTemplate.value.splice(2, 1);
				previousAccepted = editRoom.value.accepted;
				let accepted = editRoom.value.accepted;
				if (accepted !== undefined) {
					// FormObjectInput needs a different structure of the accepted values, transform them
					const acceptedKeys = Object.keys(accepted);
					let newAccepted: Object[] = [];
					acceptedKeys.forEach((key) => {
						// Translate the yivi key value to a description/label value for display
						const foundAttribute = securedRoomTemplate.value[0].options?.find((attribute) => key === attribute.value);
						let values = accepted[key].accepted_values;
						if (typeof values === 'object') {
							values = values.join(', ');
						}
						newAccepted.push({
							yivi: foundAttribute ? foundAttribute.label : key,
							values: values,
							profile: accepted[key].profile,
						});
					});
					editRoom.value.accepted = newAccepted;
				}
			}
		}

		//setSubmitButton(dialog.properties.buttons[0]);

		setData({
			name: {
				value: editRoom.value.name as string,
				validation: { required: true, allow_empty_number: false, allow_empty_object: false, allow_empty_text: false },
			},
			topic: {
				value: editRoom.value.topic as string,
			},
			room_type: {
				value: editRoom.value.room_type as string,
			},
		});
	});

	//#endregion

	async function close(returnValue: DialogButtonAction) {
		if (returnValue === 0 || (returnValue === 1 && (await submitRoom()))) {
			emit('close');
		}
	}

	async function submitRoom(): Promise<Boolean> {
		let room = { ...editRoom.value } as TSecuredRoom;

		// Normal room
		if (!props.secured) {
			if (isNewRoom.value) {
				let newRoomOptions = {
					name: room.name,
					topic: room.topic,
					visibility: 'public',
					creation_content: {
						type: editRoom.value.room_type === '' ? undefined : editRoom.value.room_type,
					},
				};
				await pubhubs.createRoom(newRoomOptions);
			} else {
				await pubhubs.renameRoom(props.room.room_id, editRoom.value.name!);

				// update topic
				await pubhubs.setTopic(props.room.room_id as string, editRoom.value.topic!);
			}
			editRoom.value = { ...emptyNewRoom };
		}
		// Secured room
		else {
			// Transform room for API
			// room.room_name = room.name;
			// delete room.name;
			room.type = RoomType.PH_MESSAGES_RESTRICTED;
			let accepted = {} as SecuredRoomAttributes;
			// @ts-ignore
			room.accepted.forEach((item: any) => {
				accepted[item.yivi] = {
					accepted_values: trimSplit(item.values),
					profile: item.profile,
				};
			});
			room.accepted = accepted;
			// Translate the keys from the description/label value back to the yivi attribute value
			const acceptedKeys = Object.keys(accepted);
			acceptedKeys.forEach((key) => {
				const foundAttribute = securedRoomTemplate.value[0].options?.find((attribute) => key === attribute.label);
				const propertyDescriptor = Object.getOwnPropertyDescriptor(room.accepted, key);
				if (room.accepted && foundAttribute && propertyDescriptor) {
					Object.defineProperty(room.accepted, foundAttribute.value, propertyDescriptor);
					delete room.accepted[key];
				}
			});
			if (isNewRoom.value) {
				try {
					await rooms.addSecuredRoom(room);
					editRoom.value = { ...emptyNewRoom };
				} catch (error) {
					errorMessage.value = t((error as Error).message);
					return false;
				}
			} else {
				// check for each item in previousAccepted whether it still exists in room.accepted
				if (!AllAttributeValuesPresent(previousAccepted, room.accepted)) {
					errorMessage.value = t('errors.do_not_remove_attributes');
					return false;
				} else {
					try {
						await rooms.changeSecuredRoom(room);
						editRoom.value = { ...emptyNewRoom };
					} catch (error) {
						errorMessage.value = t((error as Error).message);
						return false;
					}
				}
			}
		}
		return true;
	}
	/**
	 * When changing the required attributes of a secured room, users without these attributes are not removed. They can still enter.
	 * For the moment this is solved by not allowing the values to change. This function checks if all the previous attributevalues
	 * are strill present after edit
	 */
	function AllAttributeValuesPresent(previousAccepted: SecuredRoomAttributes | [], accepted: SecuredRoomAttributes | []): boolean {
		// previous there were no values
		if (Array.isArray(previousAccepted)) {
			return true;
		}

		// previous has values, but new has not
		if (Array.isArray(accepted)) {
			return false;
		}

		// For each key check if any value has been removed
		for (const key in previousAccepted) {
			if (!accepted[key]) {
				// key not present anymore
				return false;
			}
			for (const value of previousAccepted[key].accepted_values) {
				if (!accepted[key].accepted_values.includes(value)) {
					// a value has been removed
					return false;
				}
			}
		}

		// no values have been removed
		return true;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    title: title.value,
    buttons: _unref(buttonsSubmitCancel),
    onClose: _cache[9] || (_cache[9] = ($event: any) => (close($event))),
    width: "w-full max-w-[960px]"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"])),
        class: "flex flex-col gap-4"
      }, [
        _createVNode(FormLine, null, {
          default: _withCtx(() => [
            _createVNode(Label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('admin.name')), 1)
              ]),
              _: 1
            }),
            _createVNode(TextInput, {
              placeholder: _ctx.$t('admin.name'),
              modelValue: editRoom.value.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editRoom.value.name) = $event)),
              class: "~text-label-small-min/label-small-max md:w-5/6",
              onChanged: _cache[1] || (_cache[1] = ($event: any) => (_unref(updateData)('name', $event)))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(FormLine, null, {
          default: _withCtx(() => [
            _createVNode(Label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('admin.topic')), 1)
              ]),
              _: 1
            }),
            _createVNode(TextInput, {
              placeholder: _ctx.$t('admin.topic'),
              modelValue: editRoom.value.topic,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((editRoom.value.topic) = $event)),
              class: "~text-label-small-min/label-small-max md:w-5/6",
              onChanged: _cache[3] || (_cache[3] = ($event: any) => (_unref(updateData)('topic', $event)))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }),
        (!__props.secured)
          ? (_openBlock(), _createBlock(FormLine, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(Label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.room_type')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(TextInput, {
                  placeholder: _ctx.$t('admin.room_type_placeholder'),
                  modelValue: editRoom.value.room_type,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((editRoom.value.room_type) = $event)),
                  disabled: !isNewRoom.value,
                  class: "~text-label-small-min/label-small-max md:w-5/6",
                  onChanged: _cache[5] || (_cache[5] = ($event: any) => (_unref(updateData)('room_type', $event)))
                }, null, 8, ["placeholder", "modelValue", "disabled"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (__props.secured)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(FormLine, { class: "mb-2" }, {
                default: _withCtx(() => [
                  _createVNode(Label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('admin.secured_description')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(TextInput, {
                    placeholder: _ctx.$t('admin.secured_description'),
                    modelValue: editRoom.value.user_txt,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((editRoom.value.user_txt) = $event)),
                    class: "~text-label-small-min/label-small-max md:w-5/6"
                  }, null, 8, ["placeholder", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(FormLine, null, {
                default: _withCtx(() => [
                  _createVNode(Label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('admin.secured_yivi_attributes')), 1)
                    ]),
                    _: 1
                  }),
                  (editRoom.value.accepted)
                    ? (_openBlock(), _createBlock(FormObjectInput, {
                        key: 0,
                        template: securedRoomTemplate.value,
                        canAdd: isNewRoom.value,
                        canRemove: isNewRoom.value,
                        modelValue: (editRoom.value as TSecuredRoom).accepted,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (((editRoom.value as TSecuredRoom).accepted) = $event))
                      }, null, 8, ["template", "canAdd", "canRemove", "modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (_unref(errorMessage))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(Label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(errorMessage)), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "buttons"]))
}
}

})