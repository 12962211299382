import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "h-screen w-full bg-background font-body text-on-surface ~text-base-min/base-max" }
const _hoisted_2 = {
  key: 0,
  class: "h-full"
}
const _hoisted_3 = {
  key: 0,
  class: "flex h-full"
}
const _hoisted_4 = { class: "flex h-full justify-between py-2" }
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { class: "flex flex-col gap-4 p-3 md:p-4" }
const _hoisted_7 = { class: "flex flex-col gap-2" }
const _hoisted_8 = { class: "text-hub-text group flex items-center justify-between overflow-hidden rounded-xl bg-surface py-2 pl-2 pr-4" }
const _hoisted_9 = { class: "flex w-full items-center gap-2 truncate" }
const _hoisted_10 = { class: "flex h-fit w-full flex-col overflow-hidden" }
const _hoisted_11 = { class: "truncate font-bold leading-tight" }
const _hoisted_12 = { class: "leading-tight" }
const _hoisted_13 = { class: "flex flex-col gap-2" }
const _hoisted_14 = { class: "group flex items-center justify-between rounded-lg bg-surface px-4 py-2" }
const _hoisted_15 = { class: "flex h-[24px] items-center" }
const _hoisted_16 = { class: "truncate font-bold leading-tight" }
const _hoisted_17 = { class: "items-center gap-2" }
const _hoisted_18 = { class: "flex flex-col gap-2" }
const _hoisted_19 = { class: "group flex items-center justify-between rounded-lg bg-surface px-4 py-2" }
const _hoisted_20 = { class: "flex h-[24px] items-center" }
const _hoisted_21 = { class: "truncate font-bold leading-tight" }
const _hoisted_22 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_23 = { class: "group flex items-center justify-between rounded-lg bg-surface px-4 py-2" }
const _hoisted_24 = { class: "flex h-[24px] items-center" }
const _hoisted_25 = { class: "truncate font-bold leading-tight" }
const _hoisted_26 = {
  key: 1,
  class: "flex flex-col gap-2"
}
const _hoisted_27 = { class: "group flex items-center justify-between rounded-lg bg-surface px-4 py-2" }
const _hoisted_28 = { class: "flex h-[24px] items-center" }
const _hoisted_29 = { class: "truncate font-bold leading-tight" }

import { computed, getCurrentInstance, onMounted, ref, watch } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { RouteParamValue, useRouter } from 'vue-router';

	// Hub imports
	import Disclosure from '@/components/rooms/Disclosure.vue';
	import SettingsDialog from '@/components/forms/SettingsDialog.vue';
	import Dialog from '@/components/ui/Dialog.vue';
	import HeaderFooter from '@/components/ui/HeaderFooter.vue';
	import Menu from '@/components/ui/Menu.vue';
	import MenuItem from '@/components/ui/MenuItem.vue';
	import RoomList from '@/components/rooms/RoomList.vue';
	import DiscoverUsers from '@/components/rooms/DiscoverUsers.vue';
	import Badge from '@/components/elements/Badge.vue';
	import Icon from '@/components/elements/Icon.vue';
	import H3 from '@/components/elements/H3.vue';
	import Avatar from '@/components/ui/Avatar.vue';
	import { HubInformation } from '@/logic/store/hub-settings';
	import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { LOGGER } from '@/logic/foundation/Logger';
	import { SMI } from '@/logic/foundation/StatusMessage';
	import { useDialog } from '@/logic/store/dialog';
	import { useMenu } from '@/logic/store/menu';
	import { MessageType } from '@/logic/store/messagebox';
	import { usePlugins } from '@/logic/store/plugins';
	import { RoomType } from '@/logic/store/rooms';
	import { FeatureFlag, useSettings } from '@/logic/store/settings';
	import { Message, MessageBoxType, useHubSettings, useMessageBox, useRooms } from '@/logic/store/store';
	import { useUser } from '@/logic/store/user';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

	// Packages imports
	const { locale, availableLocales } = useI18n();
	const router = useRouter();
	const settings = useSettings();
	const hubSettings = useHubSettings();
	const user = useUser();
	const rooms = useRooms();
	const messagebox = useMessageBox();
	const dialog = useDialog();
	const pubhubs = usePubHubs();
	const plugins = usePlugins();
	const menu = useMenu();
	const settingsDialog = ref(false);
	const setupReady = ref(false);
	const disclosureEnabled = settings.isFeatureEnabled(FeatureFlag.disclosure);
	const isMobile = computed(() => settings.isMobileState);

	onMounted(() => {
		plugins.setPlugins(getCurrentInstance()?.appContext.config.globalProperties._plugins, router);
	});

	onMounted(async () => {
		LOGGER.trace(SMI.STARTUP, 'App.vue onMounted');

		settings.initI18b({ locale: locale, availableLocales: availableLocales });
		// set language when changed
		settings.$subscribe(() => {
			locale.value = settings.getActiveLanguage;
		});

		// Set theme based on settings
		setTheme(settings.getActiveTheme);

		// Watch for theme changes
		watch(
			() => settings.getActiveTheme,
			(newTheme) => {
				setTheme(newTheme);
			},
		);

		// Listen to isMobileState from global client
		window.addEventListener('message', (event) => {
			if (event.data?.isMobileState !== undefined) {
				settings.isMobileState = event.data.isMobileState;
			}
		});
		settings.updateIsMobile();

		// check if hash doesn't start with hub,
		// then it is running only the hub-client, so we need to do some checks
		if (!window.location.hash.startsWith('#/hub/')) {
			pubhubs.login().then(() => (setupReady.value = true));
			// Needs onboarding?
			if (user.needsOnboarding) {
				router.push({ name: 'onboarding' });
			} else {
				router.push({ name: 'home' });
			}
			// 2024 12 03 The await is removed, because of slow loading testhub
			// After the next merge to stable, in case this gives no problems,
			// the old code and comments can be removed
			// If all works well: setupReady can also be removed, since it does have no function anymmore
			// await pubhubs.login();
			// setupReady.value = true; // needed if running only the hub-client
			// router.push({ name: 'home' });
		}

		if (!user.isLoggedIn) {
			// only needed when loggedIn (then there are user settings to setup)
			setupReady.value = true;
		}
		await startMessageBox();

		LOGGER.trace(SMI.STARTUP, 'App.vue onMounted done');
	});

	async function startMessageBox() {
		if (!hubSettings.isSolo) {
			messagebox.init(MessageBoxType.Child);
			await messagebox.startCommunication(hubSettings.parentUrl);

			// Ask for Hub name etc.
			messagebox.addCallback('parentFrame', MessageType.HubInformation, (message: Message) => {
				hubSettings.initHubInformation(message.content as HubInformation);
			});

			// Listen to roomchange
			messagebox.addCallback('parentFrame', MessageType.RoomChange, async (message: Message) => {
				const roomId = message.content as RouteParamValue;
				if (rooms.currentRoomId !== roomId) {
					rooms.currentRoomId = roomId;
					await rooms.getSecuredRoomInfo(roomId);
					if (rooms.securedRoom && rooms.securedRoom !== null) {
						router.push({ name: 'secure-room', params: { id: roomId } });
					} else {
						router.push({ name: 'room', params: { id: roomId } });
					}
				}
			});

			//Listen to global menu change
			messagebox.addCallback('parentFrame', MessageType.BarHide, () => {
				hubSettings.mobileHubMenu = false;
			});

			messagebox.addCallback('parentFrame', MessageType.BarShow, () => {
				hubSettings.mobileHubMenu = true;
			});
		}
	}

	function setTheme(theme: string) {
		const html = document.documentElement;
		if (theme === 'dark') {
			html.classList.add('dark');
		} else {
			html.classList.remove('dark');
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (setupReady.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_unref(user).isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(HeaderFooter, {
                  class: _normalizeClass(["w-full bg-surface-low", [{ hidden: !_unref(hubSettings).mobileHubMenu && isMobile.value }, !isMobile.value && 'flex max-w-[40rem]']])
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["items-center gap-4 text-on-surface-dim", isMobile.value ? 'hidden' : 'flex'])
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("span", { class: "font-semibold uppercase" }, "hub", -1),
                      _createElementVNode("hr", { class: "h-[2px] grow bg-on-surface-dim" }, null, -1)
                    ]), 2),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(H3, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/'))),
                          title: _unref(hubSettings).hubName,
                          class: "font-headings font-semibold text-on-surface"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(hubSettings).hubName), 1)
                          ]),
                          _: 1
                        }, 8, ["title"])
                      ]),
                      (_unref(hubSettings).isSolo && _unref(settings).isFeatureEnabled(_unref(FeatureFlag).notifications) && _unref(rooms).totalUnreadMessages > 0)
                        ? (_openBlock(), _createBlock(Badge, {
                            key: 0,
                            class: "aspect-square h-full"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(rooms).totalUnreadMessages) + "1", 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("section", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(Avatar, {
                              user: _unref(user),
                              img: _unref(user).avatarUrl
                            }, null, 8, ["user", "img"]),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(user).displayName), 1),
                              _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(user).pseudonym ?? ''), 1)
                            ])
                          ]),
                          _createVNode(Icon, {
                            type: "cog",
                            size: "sm",
                            class: "rounded-md stroke-0 p-2 text-on-surface-variant hover:cursor-pointer hover:text-accent-primary",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => {
										settingsDialog.value = true;
										_unref(hubSettings).hideBar();
									})
                          })
                        ]),
                        _createVNode(Menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menu).getMenu, (item, index) => {
                              return (_openBlock(), _createBlock(MenuItem, {
                                key: index,
                                to: item.to,
                                icon: item.icon,
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(hubSettings).hideBar()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t(item.key)), 1)
                                ]),
                                _: 2
                              }, 1032, ["to", "icon"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("section", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('menu.rooms')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(_component_router_link, { to: { name: 'discover-rooms' } }, {
                              default: _withCtx(() => [
                                _createVNode(Icon, {
                                  type: "user_plus",
                                  class: "text-on-surface hover:text-accent-primary"
                                })
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createVNode(RoomList)
                      ]),
                      _createElementVNode("section", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t('menu.private_rooms')), 1)
                          ])
                        ]),
                        _createVNode(RoomList, {
                          roomType: _unref(RoomType).PH_MESSAGES_DM
                        }, null, 8, ["roomType"]),
                        _createVNode(DiscoverUsers)
                      ]),
                      (_unref(disclosureEnabled) && _unref(user).isAdmin)
                        ? (_openBlock(), _createElementBlock("section", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, [
                              _createElementVNode("div", _hoisted_24, [
                                _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('menu.moderation_tools')), 1)
                              ])
                            ]),
                            _createVNode(Menu, null, {
                              default: _withCtx(() => [
                                _createVNode(MenuItem, {
                                  to: { name: 'ask-disclosure' },
                                  icon: "sign"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('menu.moderation_tools_disclosure')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(user).isAdmin)
                        ? (_openBlock(), _createElementBlock("section", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, [
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t('menu.admin_tools')), 1)
                              ])
                            ]),
                            _createVNode(Menu, null, {
                              default: _withCtx(() => [
                                _createVNode(MenuItem, {
                                  to: { name: 'admin' },
                                  icon: "admin"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools_rooms')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(MenuItem, {
                                  to: { name: 'manageusers' },
                                  icon: "admin"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools_users')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(MenuItem, {
                                  to: { name: 'hub-settings' },
                                  icon: "cog"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('menu.admin_tools_hub_settings')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["h-full w-full overflow-y-auto overflow-x-hidden", { hidden: _unref(hubSettings).mobileHubMenu }])
                }, [
                  _createVNode(_component_router_view)
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(disclosureEnabled))
      ? (_openBlock(), _createBlock(Disclosure, { key: 1 }))
      : _createCommentVNode("", true),
    (settingsDialog.value)
      ? (_openBlock(), _createBlock(SettingsDialog, {
          key: 2,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (settingsDialog.value = false))
        }))
      : _createCommentVNode("", true),
    (_unref(dialog).visible)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 3,
          onClose: _unref(dialog).close
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ]))
}
}

})