import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex" }

import Button from '../elements/Button.vue';

	import { PropType, watch } from 'vue';
	import { Options, useFormInputEvents, usedEvents } from '@/logic/composables/useFormInputEvents';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonGroup',
  props: {
		options: {
			type: Array as PropType<Options>,
			required: true,
		},
		value: {
			type: [Number, String, Boolean],
			default: '',
		},
		size: {
			type: String,
			default: 'base',
		},
	},
  emits: usedEvents,
  setup(__props, { emit: __emit }) {

	// Components
	const props = __props;

	watch(
		() => props.value,
		() => {
			setValue(props.value);
		},
	);

	const emit = __emit;
	const { setValue, setOptions, selectOption, optionIsSelected, changed } = useFormInputEvents(emit);

	setValue(props.value);
	setOptions(props.options);

	function roundedClass(index: number) {
		if (index === 0) {
			return 'rounded-r-none';
		}
		if (index > 0 && index < props.options.length - 1) {
			return 'rounded-l-none rounded-r-none';
		}
		if (index === props.options.length - 1) {
			return 'rounded-l-none';
		}
		return '';
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option, index) => {
      return (_openBlock(), _createBlock(Button, {
        key: index,
        color: _unref(optionIsSelected)(option) ? 'blue' : 'gray',
        size: __props.size,
        class: _normalizeClass(roundedClass(index)),
        onClick: ($event: any) => {
				_unref(selectOption)(option);
				_unref(changed)();
			}
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option.label), 1)
        ]),
        _: 2
      }, 1032, ["color", "size", "class", "onClick"]))
    }), 128))
  ]))
}
}

})