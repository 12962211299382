import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute top-6 z-40 flex w-full justify-end"
}
const _hoisted_2 = { class: "rounded-full px-6 text-right text-on-surface-variant" }

import { useTimeFormat } from '@/logic/composables/useTimeFormat';
	import { useRooms } from '@/logic/store/rooms';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'DateDisplayer',
  props: {
		eventTimeStamp: {
			type: Number,
			required: true,
		},
		scrollStatus: {
			type: Boolean,
			required: true,
		},
	},
  setup(__props) {

	const { formattedTimeInformation } = useTimeFormat();
	const rooms = useRooms();

	const props = __props;

	function displayDate(): string {
		if (!rooms.currentRoom?.hasMessages()) return '';
		return formattedTimeInformation(props.eventTimeStamp);
	}

return (_ctx: any,_cache: any) => {
  return (props.scrollStatus && displayDate() !== '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(displayDate()), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})