import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex rounded-lg p-2" }
const _hoisted_2 = { class: "w-full p-2" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = {
  key: 0,
  class: "bg-hub-background-3 absolute bottom-8 w-32 rounded-md p-2"
}
const _hoisted_7 = { class: "~text-label-min/label-max" }
const _hoisted_8 = { class: "mt-1 flex justify-end" }
const _hoisted_9 = { class: "flex w-5 flex-col items-center" }
const _hoisted_10 = { class: "relative" }
const _hoisted_11 = {
  key: 0,
  class: "bg-hub-background-3 absolute bottom-8 w-32 rounded-md p-2"
}
const _hoisted_12 = { class: "~text-label-min/label-max" }

import Icon from '../elements/Icon.vue';

	import { SignedMessage, getDisclosedAttributes, getMessage } from '@/model/components/signedMessages';
	import { ref } from 'vue';

	type Props = {
		message: SignedMessage;
	};
	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageSigned',
  props: {
    message: {}
  },
  setup(__props: any) {

	// Components
	const props = __props;

	const showInfo = ref(false);
	const showVerificationStatus = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('message.messageSigned.heading')), 1),
        _createVNode(Icon, {
          type: 'closingCross',
          size: 'sm',
          asButton: false,
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => (showVerificationStatus.value = true)),
          onMouseout: _cache[1] || (_cache[1] = ($event: any) => (showVerificationStatus.value = false)),
          class: "text-red ml-1 mt-[3px]"
        }),
        _createElementVNode("div", _hoisted_5, [
          (showVerificationStatus.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('message.messageSigned.verificationStatus')), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("p", null, _toDisplayString(_unref(getMessage)(_ctx.message)), 1),
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getDisclosedAttributes)(_ctx.message), (attribute) => {
          return (_openBlock(), _createElementBlock("li", {
            key: attribute.id,
            class: "flex w-fit items-center rounded-full bg-black px-2 text-white"
          }, [
            _createVNode(Icon, {
              type: 'sign',
              size: 'sm',
              class: "mr-1"
            }),
            _createTextVNode(" " + _toDisplayString(//todo: add multilanguage support
						attribute.rawvalue), 1)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(Icon, {
        type: 'info',
        size: 'sm',
        asButton: false,
        class: "mb-1 mt-1",
        onMouseover: _cache[2] || (_cache[2] = ($event: any) => (showInfo.value = true)),
        onMouseout: _cache[3] || (_cache[3] = ($event: any) => (showInfo.value = false))
      }),
      _createElementVNode("div", _hoisted_10, [
        (showInfo.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('message.messageSigned.info')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})