import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white-middle dark:bg-gray flex flex-row overflow-hidden rounded-md text-black dark:text-white" }
const _hoisted_2 = { class: "mt-1 flex flex-col px-4 pb-1" }
const _hoisted_3 = { class: "font-normal ~text-label-min/label-max" }

import H3 from './H3.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Pill',
  setup(__props) {

	// Components
	
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bg-blue-light w-3" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(H3, { class: "~text-body-min/body-max font-semibold" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _: 3
      }),
      _createElementVNode("p", _hoisted_3, [
        _renderSlot(_ctx.$slots, "content")
      ])
    ])
  ]))
}
}

})