import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-nowrap"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "line-clamp-1" }

import UserDisplayName from './UserDisplayName.vue';
	import Icon from '../elements/Icon.vue';

	import { useUserColor } from '@/logic/composables/useUserColor';
	import { computed } from 'vue';
	import Room from '@/model/rooms/Room';
	import { useI18n } from 'vue-i18n';
	import { usePubHubs } from '@/logic/core/pubhubsStore';

	type Props = {
		eventId: string;
		// Whether or not to show the text "In reply to:" inside the snippet.
		showInReplyTo?: boolean;
		room: Room;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageSnippet',
  props: {
    eventId: {},
    showInReplyTo: { type: Boolean, default: false },
    room: {}
  },
  async setup(__props: any) {

let __temp: any, __restore: any

	// Components
	const { color, textColor } = useUserColor();
	const pubhubs = usePubHubs();
	const { t } = useI18n();

	const props = __props;

	const event = (
  ([__temp,__restore] = _withAsyncContext(() => pubhubs.getEvent(props.room.roomId, props.eventId))),
  __temp = await __temp,
  __restore(),
  __temp
);

	const userColor = computed(() => color(event.sender!) || 0);
	const text = computed(() => {
		return event.content?.body as string;
	});

	const redactedMessage = computed(() => {
		const inRedactedMessageIds = event.event_id && props.room.inRedactedMessageIds(event.event_id);
		const containsRedactedBecause = event.unsigned?.redacted_because != undefined;
		return inRedactedMessageIds || containsRedactedBecause;
	});

	const snippetText = computed(() => {
		return redactedMessage.value ? t('message.delete.original_message_deleted') : text.value;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex cursor-pointer items-center gap-3 truncate text-nowrap rounded-md px-2", _ctx.showInReplyTo ? 'bg-surface-high' : 'bg-surface-low'])
  }, [
    (_ctx.showInReplyTo)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('message.in_reply_to')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: _normalizeClass(_unref(textColor)(userColor.value))
    }, [
      _createVNode(UserDisplayName, {
        user: _unref(event).sender || 'Deleted user',
        room: _ctx.room
      }, null, 8, ["user", "room"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["flex w-full items-center gap-1", { 'text-accent-error': redactedMessage.value }]),
      title: snippetText.value
    }, [
      (redactedMessage.value)
        ? (_openBlock(), _createBlock(Icon, {
            key: 0,
            type: 'bin',
            size: 'sm'
          }))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_3, _toDisplayString(snippetText.value), 1)
    ], 10, _hoisted_2)
  ], 2))
}
}

})