import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = { class: "m-auto inline-block rounded-full text-center font-medium ~text-label-min/label-max" }

import { computed } from 'vue';
	import { useRooms } from '@/logic/store/store';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'UnreadMarker',
  props: {
		currentEventId: {
			type: String,
			required: true,
		},
		currentUserId: {
			type: String,
			required: true,
		},
	},
  setup(__props) {

	const rooms = useRooms();

	const props = __props;

	const unreadMarker = computed(() => {
		const readEventId = rooms.currentRoom?.getEventReadUpTo(props.currentUserId, true);
		if (rooms.unreadMessageNotification() > 0 && readEventId === props.currentEventId) return true;
		return false;
	});

return (_ctx: any,_cache: any) => {
  return (unreadMarker.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('rooms.unread_messages')), 1),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "bg-primary-accent text-on-primary-accent -mt-4 h-[1px]" }, null, -1))
      ]))
    : _createCommentVNode("", true)
}
}

})