import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-gray-darker theme-light:bg-gray-lighter absolute right-0 top-0 flex w-fit gap-4 rounded-l-xl rounded-t-none p-2"
}
const _hoisted_2 = {
  key: 0,
  class: "flex gap-1"
}
const _hoisted_3 = { class: "total" }
const _hoisted_4 = {
  key: 1,
  class: "flex gap-1"
}
const _hoisted_5 = { class: "mention" }

import { ref } from 'vue';
	import { useRooms } from '@/logic/store/store';
	import { watchEffect } from 'vue';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'InRoomNotifyMarker',
  setup(__props) {

	const rooms = useRooms();

	let totalUnreadCount = ref<number | undefined>(0);
	let totalMentionCount = ref<number | undefined>(0);

	watchEffect(() => {
		totalUnreadCount.value = rooms.unreadMessageNotification();
		totalMentionCount.value = rooms.unreadMentionNotification();
	});

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return ((_unref(totalUnreadCount) && _unref(totalUnreadCount) > 0) || (_unref(totalMentionCount) && _unref(totalMentionCount) > 0))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(totalUnreadCount) && _unref(totalUnreadCount) > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Icon, { type: "message" }),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(totalUnreadCount)), 1)
            ]))
          : _createCommentVNode("", true),
        (_unref(totalMentionCount) && _unref(totalMentionCount) > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_Icon, { type: "mention" }),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(totalMentionCount)), 1)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})