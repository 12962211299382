import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex p-2 sm:p-4" }
const _hoisted_2 = { class: "ml-2 flex flex-col" }
const _hoisted_3 = { class: "mb-2 italic text-on-surface-dim sm:mb-4" }
const _hoisted_4 = { class: "flex gap-2 rounded-md bg-accent-primary p-2 text-on-accent-primary" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = { class: "text-label-small-min/label-small-max mb-2 mt-8 grid w-full grid-cols-3 text-left text-on-surface-dim rtl:text-right" }
const _hoisted_7 = { class: "font-medium sm:px-6" }
const _hoisted_8 = { class: "font-medium" }
const _hoisted_9 = { class: "font-medium sm:px-6" }
const _hoisted_10 = { class: "max-h-[180px] w-full overflow-y-auto overflow-x-hidden ~text-label-min/label-max sm:max-h-[250px]" }
const _hoisted_11 = { class: "truncate px-2 py-2 sm:px-6 sm:py-4" }
const _hoisted_12 = { class: "px-1 py-2 sm:py-4" }
const _hoisted_13 = { class: "px-2 py-2 sm:px-6 sm:py-4" }
const _hoisted_14 = {
  key: 0,
  class: "ml-0 sm:ml-2"
}
const _hoisted_15 = ["disabled", "onUpdate:modelValue", "onChange"]
const _hoisted_16 = {
  value: 0,
  class: "active:bg-accent-primary"
}
const _hoisted_17 = { value: 50 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = ["onClick"]

import { onMounted, watch, ref } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { useUser } from '@/logic/store/user';
	import { useDialog } from '@/logic/store/dialog';
	import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { TUserJoinedRooms } from '@/model/users/TUser';
	import { TUserRole } from '@/model/users/TUser';
	import { APIService } from '@/logic/core/apiHubManagement';
	import { UserRoomPermission } from '@/model/hubmanagement/types/roomPerm';
	import { Administrator } from '@/model/hubmanagement/models/admin';
	import Avatar from '../ui/Avatar.vue';
	import Dialog from '../ui/Dialog.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'UserInRoomsForm',
  props: {
		userId: { type: String, required: true },
		displayName: { type: String, required: true },
		avatarUrl: { type: String },
		administrator: { type: Administrator, required: true },
	},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	/**
	 *  Protection against the issue of multiple admin. Only add admin that creates the room can only see the room.
	 *  TODO: Discuss in PH general meeting about the issue of PH admins - we should have only one admin who can create the room.
	 */

	const { t } = useI18n();

	const pubhubs = usePubHubs();
	const dialog = useDialog();
	const user = useUser();

	let listUserRooms = ref<UserRoomPermission[]>([]);
	let joinedMembers = ref<TUserJoinedRooms>();

	const emit = __emit;

	const props = __props;

	onMounted(async () => {
		listUserRooms.value = await fetchRoomUserPermissions();

		joinedMembers.value = await APIService.adminListJoinedRoomId(user.user.userId);
	});

	watch(
		() => props.userId,
		async () => {
			listUserRooms.value = await fetchRoomUserPermissions();

			joinedMembers.value = await APIService.adminListJoinedRoomId(user.user.userId);
		},
	);

	// Fetch permissions methods //

	async function fetchRoomUserPermissions(): Promise<UserRoomPermission[]> {
		const roomsPerm = await props.administrator.showUserPermissions(props.userId);
		const publicRoomsPerm = roomsPerm.filter((room) => room.public === true);
		// Only display public rooms and not chat.
		if (publicRoomsPerm.length === 0) {
			dialog.confirm(t('errors.no_room_error', props.userId));
			emit('close');
		}
		return publicRoomsPerm;
	}

	async function changeUserPermission(roomId: string, newRole: number) {
		try {
			await props.administrator.changePermission(props.userId, roomId, newRole);
		} catch {
			await dialog.confirm(t('admin.not_admin_perm_msg'));
		}
	}

	function showPermissionRole(powerLevel: number): string {
		switch (powerLevel) {
			case 50:
				return TUserRole.Steward;

			case 100:
				return TUserRole.Administrator;

			default:
				return TUserRole.User;
		}
	}

	// Admin Room Status Methods //

	function adminIsMember(roomId: string) {
		if (!joinedMembers.value) return;

		return joinedMembers.value?.joined_rooms.find((joinedRoomId) => roomId === joinedRoomId) !== undefined;
	}

	async function adminJoinRoom(roomId: string) {
		// Join the room
		await pubhubs.joinRoom(roomId);
		// Update the joinedMembers state

		if (joinedMembers.value) {
			joinedMembers.value.joined_rooms.push(roomId); // Add the roomId to the joined_rooms array
		}
	}

	function isRoomAdmin(roomId: string) {
		return listUserRooms.value.find((room) => room.room_id === roomId)!.room_pl === 100;
	}

	// Presentation methods //

	function getTagBasedOnRole(powerLevel: number) {
		const currentRole = showPermissionRole(powerLevel);

		if (currentRole === 'Administrator') return 'bg-accent-red';

		if (currentRole === 'Steward') return 'bg-accent-lime';

		return 'bg-accent-yellow';
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_unref(listUserRooms).length > 0)
    ? (_openBlock(), _createBlock(Dialog, {
        key: 0,
        title: _ctx.$t('admin.user_perm_heading'),
        onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(Avatar, {
              user: _unref(user),
              overrideAvatarUrl: __props.avatarUrl
            }, null, 8, ["user", "overrideAvatarUrl"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, _toDisplayString(__props.displayName), 1),
              _createElementVNode("div", _hoisted_3, _toDisplayString(__props.userId), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Icon, {
              type: "exclamation",
              class: ""
            }),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('admin.important_perm_msg')), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('admin.title_room')), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('admin.title_permission')), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('admin.title_update')), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listUserRooms), (room) => {
              return (_openBlock(), _createElementBlock("div", {
                key: room.room_id,
                class: "grid w-full grid-cols-3 border-t border-on-surface-dim"
              }, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(room.room_name), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("span", {
                    class: _normalizeClass('inline-block rounded-md px-1 sm:px-2 ' + getTagBasedOnRole(room.room_pl))
                  }, _toDisplayString(showPermissionRole(room.room_pl)), 3)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  (adminIsMember(room.room_id))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _withDirectives(_createElementVNode("select", {
                          disabled: isRoomAdmin(room.room_id),
                          class: _normalizeClass(["peer block w-full appearance-none border-0 border-on-surface-dim px-2 py-1 text-on-surface-dim focus:border-on-surface-dim focus:outline-none focus:ring-0 sm:px-8 sm:py-2", isRoomAdmin(room.room_id) ? 'bg-transparent bg-none' : '']),
                          "onUpdate:modelValue": ($event: any) => ((room.room_pl) = $event),
                          onChange: ($event: any) => (changeUserPermission(room.room_id, room.room_pl))
                        }, [
                          _createElementVNode("option", _hoisted_16, _toDisplayString(_unref(t)('admin.title_user')), 1),
                          _createElementVNode("option", _hoisted_17, _toDisplayString(_unref(t)('admin.title_steward')), 1)
                        ], 42, _hoisted_15), [
                          [_vModelSelect, room.room_pl]
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (adminJoinRoom(room.room_id)),
                          class: "ml-0 rounded bg-accent-primary px-2 py-1 transition sm:ml-2 sm:px-3"
                        }, _toDisplayString(_ctx.$t('admin.join')), 9, _hoisted_19)
                      ]))
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["title"]))
    : _createCommentVNode("", true)
}
}

})