import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-b-1 border-x-1 bg-hub-background-2 dark:text-white-middle absolute top-40 z-30 m-auto w-1/2 rounded-md p-8" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "bg-blue-middle bg-blue-light text-white-middle flex justify-center rounded-md" }
const _hoisted_4 = { class: "text-gray-darker w-full text-left text-lg dark:text-white rtl:text-right" }
const _hoisted_5 = { class: "px-6 py-4" }
const _hoisted_6 = { class: "px-6 py-4" }
const _hoisted_7 = { class: "text-gray-dark font-semibold" }
const _hoisted_8 = ["onClick"]

import Avatar from '../ui/Avatar.vue';

	import { onMounted, watch, ref } from 'vue';
	import { APIService } from '@/logic/core/apiHubManagement';
	import { UserAccount } from '@/model/hubmanagement/types/userAccount';
	import { AccessToken } from '@/model/hubmanagement/types/authType';
	import { ManagementUtils } from '@/model/hubmanagement/utility/managementutils';
	import { useDialog } from '@/logic/store/dialog';
	import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { useI18n } from 'vue-i18n';
	import { useUser } from '@/logic/store/user';
	import { User as MatrixUser } from 'matrix-js-sdk';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'AdminMembers',
  props: {
		roomId: {
			type: String,
			required: true,
		},
	},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	const { t } = useI18n();

	const adminMembersId = ref<string[]>([]);

	// Map is used to store <admin id, authenticated media url>
	const updateAuthenticatedUrlMap = ref<Map<string, string>>(new Map());

	const dialog = useDialog();

	const user = useUser();

	const emit = __emit;

	const pubhubs = usePubHubs();

	const props = __props;

	onMounted(async () => {
		adminMembersId.value = await ManagementUtils.getAdminUsersId(props.roomId);

		// New code to populate the updateAuthenticatedUrlMap
		for (const id of adminMembersId.value) {
			let url = await getAuthorizedAvatarUrl(id);

			if (url !== null) updateAuthenticatedUrlMap.value.set(id, url);
		}
	});

	watch(
		() => props.roomId,
		async () => {
			// For new room, we will have new admin Ids .
			updateAuthenticatedUrlMap.value.clear();

			//Fetch all admin ids in the room.
			adminMembersId.value = await ManagementUtils.getAdminUsersId(props.roomId);

			for (const id of adminMembersId.value) {
				// Get authenticated avatar url for the ids.
				let url = await getAuthorizedAvatarUrl(id);

				// Store it in a map
				if (url !== null) updateAuthenticatedUrlMap.value.set(id, url);
			}
		},
	);

	function getMatrixUser(userId: string): MatrixUser {
		return pubhubs.client.getUser(userId)!;
	}
	async function getAuthorizedAvatarUrl(userId: string) {
		const userAccount: UserAccount = await APIService.adminQueryAccount(userId);

		return userAccount.avatar_url && (await pubhubs.getAuthorizedMediaUrl(userAccount.avatar_url));
	}

	// Flow to trigger force join and make 'this user admin' as the admin of the room.
	async function forceRejoinFlow(pastAdminUserId: string) {
		let joinState;
		try {
			// Member is not in the room, then prompt for join room message and then join flow begins.
			joinState = await promptAndAttemptToJoin(pastAdminUserId);
			joinState && (await promptAndMakeAdmin(props.roomId));
		} catch (error: any) {
			dialog.showError(error as string);
		}
		emit('close');
	}

	async function promptAndAttemptToJoin(pastAdminUserId: string): Promise<number> {
		const joinRoomState = await dialog.yesno(t('admin.msg_prev_admin_join'));

		if (joinRoomState) {
			// Past admin join
			const accessToken: AccessToken = await APIService.adminUserLogin(pastAdminUserId);
			await APIService.forceRoomJoin(props.roomId, accessToken.access_token);
			// This admin joins the room as well.
			await pubhubs.client.joinRoom(props.roomId);
		}
		return joinRoomState as number;
	}

	async function promptAndMakeAdmin(roomId: string): Promise<void> {
		const confirmState = await dialog.confirm(t('admin.msg_make_admin'));
		confirmState && (await APIService.makeRoomAdmin(roomId, user.user.userId));
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "bg-gray-middle absolute inset-0 z-20 h-full opacity-75" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "light:text-black theme-light:text-black mx-2 my-2 mt-4 text-lg font-bold" }, "Room Join", -1)),
        _createVNode(_component_Icon, {
          type: "close",
          size: "md",
          class: "hover:text-red theme-light:text-gray theme-light:hover:text-red mt-4",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("hr", { class: "border-gray-lighter mx-8 mb-4 mt-2" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Icon, {
          type: "exclamation",
          class: "inline"
        }),
        _createElementVNode("span", null, _toDisplayString(_unref(t)('admin.join_warning')), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("table", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("thead", { class: "~text-body-min/body-max" }, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", {
                scope: "col",
                class: "w-8 px-6 py-3"
              }),
              _createElementVNode("th", {
                scope: "col",
                class: "py-3"
              }),
              _createElementVNode("th", {
                scope: "col",
                class: "px-6 py-3"
              })
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adminMembersId.value, (adminId) => {
              return (_openBlock(), _createElementBlock("tr", { key: adminId }, [
                _createElementVNode("td", _hoisted_5, [
                  _createVNode(Avatar, {
                    user: getMatrixUser(adminId),
                    overrideAvatarUrl: updateAuthenticatedUrlMap.value.get(adminId),
                    class: "h-10 w-10"
                  }, null, 8, ["user", "overrideAvatarUrl"])
                ]),
                _createElementVNode("td", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(adminId), 1)
                ]),
                _createElementVNode("td", null, [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (forceRejoinFlow(adminId)),
                    class: "bg-blue hover:bg-blue-dark ml-4 rounded px-3 py-1 text-white transition"
                  }, _toDisplayString(_ctx.$t('admin.join')), 9, _hoisted_8)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 64))
}
}

})