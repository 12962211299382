import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Icon from '@/components/elements/Icon.vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalbarButton',
  props: {
		type: {
			type: String,
			required: true,
		},
		size: {
			type: String,
			default: 'base',
		},
	},
  emits: ['click'],
  setup(__props, { emit: __emit }) {

	// Global imports
	const props = __props;

	const emit = __emit;

	function handleClick(event: Event) {
		emit('click', event);
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Icon, {
    type: __props.type,
    class: "rounded-md bg-background p-2 text-accent-secondary hover:cursor-pointer hover:bg-accent-secondary hover:text-background dark:text-on-surface dark:hover:bg-on-surface",
    size: __props.size,
    onClick: handleClick
  }, null, 8, ["type", "size"]))
}
}

})