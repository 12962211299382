import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import TabHeader from '../ui/TabHeader.vue';
	import TabPill from '../ui/TabPill.vue';
	import Icon from '../elements/Icon.vue';
	import TabContainer from '../ui/TabContainer.vue';
	import TabContent from '../ui/TabContent.vue';
	import FormLine from './FormLine.vue';
	import Label from './Label.vue';
	import TextInput from './TextInput.vue';
	import TextArea from './TextArea.vue';
	import Checkbox from './Checkbox.vue';
	import Select from './Select.vue';
	import AutoComplete from './AutoComplete.vue';

	import { ref, inject } from 'vue';
	import { InputType, FormObjectInputTemplate } from '@/logic/composables/useFormInputEvents';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'FormObjectInputContent',
  props: {
		template: {
			type: Array<FormObjectInputTemplate>,
			required: true,
		},
		modelValue: {
			type: Array<any>,
			required: true,
		},
		canAdd: {
			type: Boolean,
			required: false,
			default: true,
		},
		canRemove: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
  emits: ['input'],
  setup(__props, { emit: __emit }) {

	// Components
	const setActiveTab = inject('setActiveTab') as Function;
	const removeTab = inject('removeTab') as Function;

	const emit = __emit;

	const props = __props;

	let defaultItem = {} as any;
	props.template.forEach((item) => {
		defaultItem[item.key] = item.default;
	});

	const list = ref(props.modelValue);
	if (list.value.length === 0) {
		list.value.push({ ...defaultItem });
	}

	function addItem() {
		if (props.canAdd) {
			list.value.push({ ...defaultItem });
			setActiveTab(list.value.length);
		}
	}

	function removeItem(index: number) {
		list.value.splice(index, 1);
		removeTab();
	}

	function update(index: number, key: string, value: InputType) {
		list.value[index][key] = value;
		emit('input', list.value);
	}

	function pillTitle(index: number) {
		return `#${index + 1}`;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(TabHeader, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
          return (_openBlock(), _createBlock(TabPill, {
            key: index,
            class: "h-8"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(pillTitle(index)) + " ", 1),
              (props.canRemove && index > 0)
                ? (_openBlock(), _createBlock(Icon, {
                    key: 0,
                    type: "remove",
                    class: "text-red float-right ml-1 cursor-pointer opacity-50 hover:opacity-100",
                    onClick: _withModifiers(($event: any) => (removeItem(index)), ["stop"])
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (props.canAdd)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "tabs-tab theme-light:border-gray z-20 float-right inline-block h-8 cursor-pointer rounded-t border border-b-0 px-2 py-1",
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (addItem()), ["stop"]))
            }, [
              _createVNode(Icon, {
                type: "plus",
                class: "text-green-dark cursor-pointer opacity-70 hover:opacity-100",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (addItem()), ["stop"]))
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(TabContainer, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
          return (_openBlock(), _createBlock(TabContent, { key: index }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.template, (type, ti) => {
                return (_openBlock(), _createBlock(FormLine, { key: ti }, {
                  default: _withCtx(() => [
                    _createVNode(Label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(type.label), 1)
                      ]),
                      _: 2
                    }, 1024),
                    (type.type === 'text')
                      ? (_openBlock(), _createBlock(TextInput, {
                          key: 0,
                          placeholder: (index + 1).toString(),
                          value: item[type.key],
                          disabled: type.disabled,
                          onInput: ($event: any) => (update(index, type.key, $event.target.value))
                        }, null, 8, ["placeholder", "value", "disabled", "onInput"]))
                      : _createCommentVNode("", true),
                    (type.type === 'textarea')
                      ? (_openBlock(), _createBlock(TextArea, {
                          key: 1,
                          class: "bg-surface-low",
                          modelValue: item[type.key],
                          disabled: type.disabled,
                          maxLength: type.maxLength,
                          onInput: ($event: any) => (update(index, type.key, $event.target.value))
                        }, null, 8, ["modelValue", "disabled", "maxLength", "onInput"]))
                      : _createCommentVNode("", true),
                    (type.type === 'checkbox')
                      ? (_openBlock(), _createBlock(Checkbox, {
                          key: 2,
                          value: item[type.key],
                          disabled: type.disabled,
                          onInput: ($event: any) => (update(index, type.key, $event.target.checked))
                        }, null, 8, ["value", "disabled", "onInput"]))
                      : _createCommentVNode("", true),
                    (type.type === 'select')
                      ? (_openBlock(), _createBlock(Select, {
                          key: 3,
                          value: item[type.key],
                          options: type.options,
                          disabled: type.disabled,
                          onInput: ($event: any) => (update(index, type.key, $event.target.value))
                        }, null, 8, ["value", "options", "disabled", "onInput"]))
                      : _createCommentVNode("", true),
                    (type.type === 'autocomplete')
                      ? (_openBlock(), _createBlock(AutoComplete, {
                          key: 4,
                          value: item[type.key],
                          options: type.options,
                          disabled: type.disabled,
                          onInput: ($event: any) => (update(index, type.key, $event.target.value)),
                          onChanged: ($event: any) => (update(index, type.key, $event))
                        }, null, 8, ["value", "options", "disabled", "onInput", "onChanged"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}
}

})