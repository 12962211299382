import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = ["alt", "src"]

import Popover from '../ui/Popover.vue';

	import { useMatrixFiles } from '@/logic/composables/useMatrixFiles';
	import { TImageMessageEventContent } from '@/model/events/TMessageEvent';
	import { ref, onMounted } from 'vue';
	import { FeatureFlag, useSettings } from '@/logic/store/settings';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageImage',
  props: {
    message: {}
  },
  setup(__props: any) {

	// Components
	const matrixFiles = useMatrixFiles();
	const settings = useSettings();
	const showFullImage = ref(false);
	const authMediaUrl = ref<string | undefined>(undefined);

	const props = __props;

	onMounted(async () => {
		authMediaUrl.value = await matrixFiles.useAuthorizedMediaUrl(props.message.url, settings.isFeatureEnabled(FeatureFlag.authenticatedMedia));
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (authMediaUrl.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          alt: _ctx.message.body,
          src: authMediaUrl.value,
          class: "max-h-[25rem] w-[20rem] cursor-pointer rounded-md object-contain",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (showFullImage.value = true), ["stop"]))
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (showFullImage.value)
      ? (_openBlock(), _createBlock(Popover, {
          key: 1,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (showFullImage.value = false)),
          class: "fixed left-0 top-0 z-50 flex h-screen w-screen",
          "show-closing-cross": true
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: _ctx.message.body,
              src: authMediaUrl.value,
              class: "m-auto h-4/5 w-4/5 object-contain"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})