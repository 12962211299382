import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "flex items-center gap-4 py-2" }

import Room from '@/model/rooms/Room';
	import { TRoomMember } from '@/model/rooms/TRoomMember';
	import { useRooms } from '@/logic/store/store';
	import { computed, onMounted, ref, watch } from 'vue';

	import Avatar from './Avatar.vue';

	type Props = {
		msg?: string;
		left: number;
		top: number;
		room: Room;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Mention',
  props: {
    msg: { default: undefined },
    left: { default: 0 },
    top: { default: 0 },
    room: { default: undefined }
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

	const emit = __emit;

	const isVisible = ref(false);

	// position of @-sign of user in the current message
	const positionOfAt = ref(0);

	const rooms = useRooms();
	let users = ref([] as TRoomMember[]);
	const elContainer = ref<HTMLElement | null>(null);

	const props = __props;

	onMounted(() => {
		initUserMention();
	});

	// watch for changes in the props.msg to control visibility
	watch(
		() => props.msg,
		() => {
			initUserMention();
		},
	);

	function initUserMention() {
		// if the current message includes a @, we need to get all other users in the room
		// when it does not, we keep the user-dialog invisible
		if (props.msg?.includes('@')) {
			users.value = rooms.currentRoom?.getOtherJoinedMembers() || [];

			// check at which position the @ is and if there is a list of
			// filtered users to check if we must display the dialog
			if (props.msg?.endsWith('@')) {
				positionOfAt.value = props.msg.length;
				isVisible.value = true;
			} else if ((props.msg?.length ?? 0) < positionOfAt.value && positionOfAt.value > 0) {
				positionOfAt.value = 0;
				isVisible.value = false;
			} else if (filteredUsers.value.length > 0) {
				isVisible.value = true;
			}
		} else {
			isVisible.value = false;
		}
	}

	const filteredUsers = computed(() => {
		const query = props.msg ?? '';

		if (query.endsWith('@')) {
			return displayAllUsers();
		} else {
			return filterUsers(query);
		}
	});

	function displayAllUsers() {
		return users.value;
	}

	function filterUsers(query: string) {
		const searchTerm = query.slice(query.lastIndexOf('@') + 1);
		const newUserList = users.value.filter((user) => user.rawDisplayName !== undefined && user.rawDisplayName.toLowerCase().includes(searchTerm.toLowerCase()));
		return newUserList;
	}

	function clickedItem(item: any) {
		emit('click', item);
		positionOfAt.value = 0;
	}

	function getStyle() {
		if (!elContainer.value) return;
		return {
			left: `${props.left}px`,
			top: `${props.top - 40 - elContainer.value.clientHeight}px`,
		};
	}

return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "elContainer",
        ref: elContainer,
        style: _normalizeStyle(getStyle()),
        class: "scrollbar bg-lightgray-light dark:bg-gray fixed max-h-52 w-fit overflow-y-auto rounded-lg shadow-lg"
      }, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredUsers.value, (member, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "hover:bg-lightgray-dark hover:dark:bg-gray-middle group cursor-pointer px-4",
              onClick: _withModifiers(($event: any) => (clickedItem(member)), ["stop"])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(Avatar, {
                  user: _ctx.room.getMember(member.userId, true)
                }, null, 8, ["user"]),
                _createElementVNode("div", null, _toDisplayString(member.rawDisplayName), 1)
              ])
            ], 8, _hoisted_1))
          }), 128))
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})