import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
	import { buttonSizes } from '@/assets/sizes';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
		color: {
			type: String,
			default: 'blue',
		},
		size: {
			type: String,
			default: 'base',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
  setup(__props) {

	const colorClass: { [key: string]: string } = {
		disabled: 'opacity-50 !cursor-not-allowed',
		text: 'shadow-none hover:opacity-75',
		blue: 'bg-accent-primary hover:opacity-75 text-background dark:text-on-surface',
		red: 'bg-accent-red hover:opacity-75 text-background dark:text-on-surface',
		gray: 'bg-surface hover:opacity-75 text-on-surface',
	};

	const props = __props;

	const buttonClass = computed(() => {
		let c = buttonSizes[props.size] + ' ';
		if (props.disabled) {
			c += colorClass['disabled'];
		} else {
			c += colorClass[props.color];
		}
		return c;
	});

	function click(event: Event) {
		if (props.disabled) {
			event.stopImmediatePropagation();
		}
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([buttonClass.value, "relative block cursor-pointer rounded-lg text-center font-semibold transition-all duration-150 ease-in-out"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (click($event)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})