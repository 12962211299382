import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "z-40 rounded-md bg-surface-high" }

type Props = {
		showClosingCross?: boolean;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Popover',
  props: {
    showClosingCross: { type: Boolean, default: false }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

	const emit = __emit;

	window.addEventListener(
		'keydown',
		(e) => {
			if (e.key === 'Escape') {
				close();
			}
		},
		{ once: true },
	);

	

	async function close() {
		emit('close');
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showClosingCross)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          type: "closingCross",
          size: "base",
          asButton: true,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (close())),
          class: "absolute right-2 top-2"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ])), [
    [_directive_click_outside, close]
  ])
}
}

})