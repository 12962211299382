import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-3 mb-0 flex items-center gap-2 rounded-md bg-surface-low p-2" }
const _hoisted_2 = { class: "truncate text-nowrap ~text-label-tiny-min/label-tiny-max" }
const _hoisted_3 = { class: "h-full flex-1 overflow-y-scroll pb-8 pt-4" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]

import { onMounted, ref, computed, reactive, Reactive, watch, onUnmounted, nextTick } from 'vue';
	import { LOGGER } from '@/logic/foundation/Logger';
	import { SMI } from '@/logic/foundation/StatusMessage';
	import Room from '@/model/rooms/Room';
	import { RelationType, RoomEmit } from '@/model/constants';
	import MessageInput from '../forms/MessageInput.vue';
	import { TMessageEvent, TMessageEventContent } from '@/model/events/TMessageEvent';
	import { Thread } from 'matrix-js-sdk';
	import { useUser } from '@/logic/store/user';

	// Components
	import RoomEvent from './RoomEvent.vue';
	import DeleteMessageDialog from '../forms/DeleteMessageDialog.vue';
	import { MatrixEvent } from 'matrix-js-sdk';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomThread',
  props: {
		room: {
			type: Room,
			required: true,
		},
		scrollToEventId: String,
	},
  emits: [RoomEmit.ThreadLengthChanged, RoomEmit.ScrolledToEventId],
  setup(__props, { emit: __emit }) {

	const props = __props;

	let deletedEvents: Reactive<MatrixEvent[]> = reactive<MatrixEvent[]>([]);
	let threadEvents: Reactive<MatrixEvent[]> = reactive<MatrixEvent[]>([]);
	const user = useUser();
	const emit = __emit;

	const filteredEvents = computed(() => {
		// return all threadEvents that are not in the deletedEvents
		return threadEvents.filter((event) => !deletedEvents.some((deletedEvent) => deletedEvent.getId() === event.getId()));
	});

	const numberOfThreadEvents = computed(() => {
		return filteredEvents.value.length - 1;
	});

	watch(
		() => props.room.currentThread?.threadId,
		() => {
			changeThreadId();
		},
		{ immediate: true },
	);

	// Watch for currently visible eventId
	watch(
		() => props.scrollToEventId,
		(newValue, oldValue) => {
			// nextTick to make sure the element is already rendered before scrolling
			nextTick(() => {
				onScrollToEventId(newValue, oldValue);
			});
		},
		{ immediate: true },
	);

	watch(
		() => filteredEvents.value.length,
		() => {
			emit(RoomEmit.ThreadLengthChanged, numberOfThreadEvents.value ?? 0);
		},
	);

	const elThreadTimeline = ref<HTMLElement | null>(null);
	const showConfirmDelMsgDialog = ref(false);
	const eventToBeDeleted = ref<TMessageEvent>();

	onMounted(() => {
		props.room.listenToThreadNewReply(newReplyListener.bind(this));
		props.room.listenToThreadUpdate(updateReplyListener.bind(this));
		LOGGER.log(SMI.ROOM_THREAD, `RoomThread mounted `);
	});

	onUnmounted(() => {
		props.room.stopListeningToThreadNewReply(newReplyListener.bind(this));
		props.room.stopListeningToThreadUpdate(updateReplyListener.bind(this));
	});

	function closeThread() {
		props.room.setCurrentThreadId(undefined);
	}

	/**
	 * Change the threadId to the current threadId of the room and jump to last event
	 */
	async function changeThreadId() {
		await getThreadEvents();
		if (props.room.getCurrentEventId()) {
			scrollToEvent(props.room.getCurrentEventId()!, { position: 'center', select: 'Highlight' });
		} else {
			const lastEvent = filteredEvents.value[filteredEvents.value.length - 1];
			if (lastEvent?.event?.event_id) {
				scrollToEvent(lastEvent.event.event_id, { position: 'end' });
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async function onScrollToEventId(newEventId?: string, oldEventId?: string) {
		if (!newEventId) return;
		scrollToEvent(newEventId, { position: 'center', select: 'Highlight' });
	}

	/**
	 * Get the thread events from the room and set them to the threadEvents array
	 * At the moment all messages from the thread are loaded at once
	 * Possibly in the future depending of the size of threads this may have to be changed
	 */
	async function getThreadEvents() {
		await props.room.getCurrentThreadEvents().then((events) => {
			threadEvents.splice(0, threadEvents.length, ...events);
		});
	}

	function onInReplyToClick(inReplyToId: string) {
		scrollToEvent(inReplyToId, { position: 'center', select: 'Highlight' });
	}

	// parameters are not used, but needed to listen to the event, so:
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async function newReplyListener(_thread: Thread, _threadEvent: MatrixEvent) {
		// make sure the thread is a valid thread
		props.room.AssureThread();

		// update thread events
		await getThreadEvents();
		const newestMessageEventId = threadEvents[threadEvents.length - 1]?.event.event_id;
		if (newestMessageEventId) {
			// Make sure the new event is in the timeline when the user is the sender and/or the previous newest event was visible
			const newestEvent = props.room.currentThread?.thread?.findEventById(newestMessageEventId)?.event;
			const messageSendByUser = newestEvent?.sender === user.user.userId;
			// When the new event is send by the user: scroll to the message
			if (messageSendByUser && newestEvent?.event_id) {
				await scrollToEvent(newestEvent.event_id);
			}
		}
	}

	// Listen to update event, also for deletion. Thread parameter is of current thread, not of changed one,
	// so we need to always perform this
	// parameters are not used, but needed to listen to the event, so:
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async function updateReplyListener(thread: Thread) {
		props.room.AssureThread();
		await getThreadEvents();
		// check for events of which the related event does no longer exist and add them to the RedeactedEventIds to make sure they are correctly displayed
		threadEvents.forEach((event) => {
			const eventId = event.event?.content?.[RelationType.RelatesTo]?.[RelationType.InReplyTo]?.event_id;
			if (eventId) {
				if (!threadEvents.find((e) => e.event.event_id === eventId)) {
					props.room.addToRedactedEventIds(eventId);
				}
			}
		});
	}

	async function scrollToEvent(eventId: string, options: { position: 'start' | 'center' | 'end'; select?: 'Highlight' | 'Select' } = { position: 'start' }) {
		LOGGER.log(SMI.ROOM_THREAD, `scroll to event: ${eventId}`, { eventId });

		const doScroll = (elEvent: Element) => {
			elEvent.scrollIntoView({ block: options.position });

			// Style the event depending on the select option.
			if (options.select === 'Highlight') {
				elEvent.classList.add('highlighted');
				window.setTimeout(() => {
					elEvent.classList.add('unhighlighted');
					window.setTimeout(() => {
						elEvent.classList.remove('highlighted');
					}, 500);
				}, 2000);
			}
		};

		if (elThreadTimeline.value) {
			const elEvent = elThreadTimeline.value?.querySelector(`[id="${eventId}"]`);
			if (elEvent) {
				doScroll(elEvent);
				emit(RoomEmit.ScrolledToEventId);
			}
		}
	}

	function confirmDeleteMessage(event: TMessageEvent) {
		eventToBeDeleted.value = event;
		showConfirmDelMsgDialog.value = true;
	}

	function deleteMessage(event: TMessageEvent<TMessageEventContent> | undefined) {
		if (event) {
			let deletedEvent = threadEvents.find((e) => e.event.event_id === event.event_id);
			props.room.deleteThreadMessage(event, deletedEvent?.threadRootId);
			if (deletedEvent) {
				deletedEvents.push(deletedEvent as MatrixEvent);
			}
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "elThreadTimeline",
      ref: elThreadTimeline,
      class: "relative flex h-full w-full shrink-0 flex-col border-l border-surface-high bg-background md:w-[33%]"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: closeThread,
          class: "rounded-md p-1"
        }, [
          _createVNode(_component_Icon, {
            type: 'arrow',
            size: 'sm'
          })
        ]),
        _createElementVNode("p", _hoisted_2, "Thread (" + _toDisplayString(numberOfThreadEvents.value ?? 0) + ")", 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (filteredEvents.value.length === 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref: "elRoomEvent",
              id: props.room.currentThread?.rootEvent?.event.event_id
            }, [
              _createVNode(RoomEvent, {
                room: __props.room,
                event: props.room.currentThread?.rootEvent?.event,
                viewFromThread: true,
                class: "room-event",
                onInReplyToClick: onInReplyToClick,
                onDeleteMessage: confirmDeleteMessage
              }, null, 8, ["room", "event"])
            ], 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredEvents.value, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.event.event_id
          }, [
            _createElementVNode("div", {
              class: "mx-3 overflow-hidden rounded-md",
              ref_for: true,
              ref: "elRoomEvent",
              id: item.event.event_id
            }, [
              _createVNode(RoomEvent, {
                room: __props.room,
                event: item.event,
                viewFromThread: true,
                class: "room-event",
                onInReplyToClick: onInReplyToClick,
                onDeleteMessage: confirmDeleteMessage
              }, null, 8, ["room", "event"])
            ], 8, _hoisted_5)
          ]))
        }), 128))
      ]),
      (__props.room)
        ? (_openBlock(), _createBlock(MessageInput, {
            key: 0,
            class: "z-10 -mt-4",
            room: __props.room,
            "in-thread": true
          }, null, 8, ["room"]))
        : _createCommentVNode("", true)
    ], 512),
    (showConfirmDelMsgDialog.value)
      ? (_openBlock(), _createBlock(DeleteMessageDialog, {
          key: 0,
          event: eventToBeDeleted.value,
          room: __props.room,
          "view-from-thread": true,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (showConfirmDelMsgDialog.value = false)),
          onYes: _cache[1] || (_cache[1] = ($event: any) => (deleteMessage(eventToBeDeleted.value)))
        }, null, 8, ["event", "room"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})