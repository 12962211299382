import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const logoLightUrl = '/img/pubhubs-logo.svg';
	const logoDarkUrl = '/img/pubhubs-logo-dark.svg';

export default /*@__PURE__*/_defineComponent({
  __name: 'Logo',
  setup(__props) {

	// Hub imports
	
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      alt: "PubHubs logo",
      src: logoLightUrl,
      class: "h-full w-full dark:hidden"
    }),
    _createElementVNode("img", {
      alt: "PubHubs logo",
      src: logoDarkUrl,
      class: "hidden h-full w-full dark:block"
    })
  ], 64))
}
}

})