import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "mt-4 flex justify-center" }
const _hoisted_5 = { class: "text-on-surface-dim ~text-label-min/label-max" }

import { useMatrixFiles } from '@/logic/composables/useMatrixFiles';
	import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { useRooms } from '@/logic/store/store';
	import filters from '@/logic/core/filters';
	import { buttonsOkCancel } from '@/logic/store/dialog';
	import { fileUpload } from '@/logic/composables/fileUpload';
	import { useI18n } from 'vue-i18n';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'FileUploadDialog',
  props: {
		file: {
			type: File,
			required: true,
		},
		blobURL: {
			type: String,
			required: true,
		},
		threadId: {
			type: String,
		},
	},
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	const { allTypes, uploadUrl } = useMatrixFiles();
	const { t } = useI18n();

	const rooms = useRooms();
	const pubhubs = usePubHubs();

	const { imageTypes } = useMatrixFiles();
	const emit = __emit;

	const props = __props;

	async function close(action: number = 0) {
		if (action === 1) {
			submit();
		} else {
			emit('close');
		}
	}

	async function submit() {
		// display the component
		const accessToken = pubhubs.Auth.getAccessToken();
		// TODO errorhandling
		if (!accessToken) {
			return;
		}

		const errorMsg = t('errors.file_upload');

		const syntheticEvent = {
			currentTarget: {
				files: [props.file],
			},
		} as unknown as Event;

		fileUpload(errorMsg, accessToken, uploadUrl, allTypes, syntheticEvent, (url) => {
			if (imageTypes.includes(props.file?.type)) {
				pubhubs.addImage(rooms.currentRoomId, props.threadId, url);
			} else {
				pubhubs.addFile(rooms.currentRoomId, props.threadId, props.file as File, url);
			}
			URL.revokeObjectURL(props.blobURL);
			emit('close');
		});
	}

return (_ctx: any,_cache: any) => {
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createBlock(_component_Dialog, {
    buttons: _unref(buttonsOkCancel),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (close($event)))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('file.upload_file')), 1)
    ]),
    default: _withCtx(() => [
      (_unref(imageTypes).includes(props.file?.type))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: __props.blobURL,
              class: "max-h-96 max-w-full rounded-lg"
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(__props.file.name) + " (" + _toDisplayString(`${_unref(filters).formatBytes(__props.file.size, 2)}`) + ")", 1)
      ])
    ]),
    _: 1
  }, 8, ["buttons"])), [
    [_directive_click_outside, close]
  ])
}
}

})