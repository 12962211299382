import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center ~text-label-small-min/label-small-max" }

import { useTimeFormat } from '@/logic/composables/useTimeFormat';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'EventTime',
  props: {
		timestamp: {
			type: Number,
			required: true,
		},
		showDate: {
			type: Boolean,
			required: true,
		},
	},
  setup(__props) {

	const { formatTimestamp, formattedTimeInformation } = useTimeFormat();

	const props = __props;

	function getDateTime() {
		return props.showDate ? formattedTimeInformation(props.timestamp) : formatTimestamp(props.timestamp);
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(getDateTime()), 1)
  ]))
}
}

})