import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-semibold uppercase" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-fit items-center gap-3 overflow-hidden"
}
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex gap-4" }
const _hoisted_5 = { class: "flex h-full w-full justify-between overflow-hidden" }
const _hoisted_6 = { class: "flex h-full w-full flex-col overflow-hidden" }

import HeaderFooter from '@/components/ui/HeaderFooter.vue';
	import Icon from '@/components/elements/Icon.vue';
	import H3 from '@/components/elements/H3.vue';
	import TruncatedText from '@/components/elements/TruncatedText.vue';
	import PrivateRoomName from '@/components/rooms/PrivateRoomName.vue';
	import SearchInput from '@/components/forms/SearchInput.vue';
	import RoomTimeline from '@/components/rooms/RoomTimeline.vue';
	import RoomName from '@/components/rooms/RoomName.vue';
	import RoomThread from '@/components/rooms/RoomThread.vue';
	import GlobalBarButton from '@/components/ui/GlobalbarButton.vue';

	import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { LOGGER } from '@/logic/foundation/Logger';
	import { SMI } from '@/logic/foundation/StatusMessage';
	import { TSearchParameters } from '@/model/search/TSearch';
	import { useHubSettings } from '@/logic/store/hub-settings';
	import { PluginProperties, usePlugins } from '@/logic/store/plugins';
	import { useRooms } from '@/logic/store/rooms';
	import { useUser } from '@/logic/store/user';
	import { TPublicRoom } from '@/model/rooms/TPublicRoom';
	import { TSecuredRoom } from '@/model/rooms/TSecuredRoom';
	import { computed, onMounted, ref, watch } from 'vue';
	import { useRoute, useRouter } from 'vue-router';
	import { useSettings } from '@/logic/store/settings';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Room',
  props: {
		id: { type: String, required: true },
	},
  setup(__props) {

	// Components
	const route = useRoute();
	const rooms = useRooms();
	const user = useUser();
	const router = useRouter();
	const plugins = usePlugins();
	const plugin = ref(false as boolean | PluginProperties);
	const hubSettings = useHubSettings();
	const currentRoomToEdit = ref<TSecuredRoom | TPublicRoom | null>(null);
	const showEditRoom = ref(false);
	const secured = ref(false);
	const settings = useSettings();
	const isMobile = computed(() => settings.isMobileState);

	const pubhubs = usePubHubs();

	//Passed by the router
	const props = __props;

	const searchParameters = ref<TSearchParameters>({ roomId: props.id, term: '' });

	const room = computed(() => {
		let r = rooms.rooms[props.id];
		if (!r) {
			// I want the side effect that should be avoided according to the lint rule.
			// eslint-disable-next-line
			router.push({ name: 'error-page', query: { errorKey: 'errors.cant_find_room' } });
		}
		return r;
	});

	onMounted(() => {
		update();
		LOGGER.log(SMI.ROOM, `Room mounted `);
	});

	watch(route, () => {
		if (rooms.currentRoom) {
			rooms.currentRoom.setCurrentThreadId(undefined); // reset current thread
			rooms.currentRoom.setCurrentEventId(undefined); // reset current event
		}
		update();
	});

	function currentThreadLengthChanged(newLength: number) {
		room.value.setCurrentThreadLength(newLength);
	}

	async function update() {
		hubSettings.hideBar();
		rooms.changeRoom(props.id);

		const userIsMemberOfRoom = await pubhubs.isUserRoomMember(user.user.userId, props.id);
		if (!userIsMemberOfRoom) {
			// if not a member: try to join, otherwise go to the hubpage
			pubhubs.joinRoom(props.id).catch(() => router.push({ name: 'hubpage' }));
		}

		if (!rooms.currentRoom) return;
		searchParameters.value.roomId = rooms.currentRoom.roomId;
		plugin.value = plugins.hasRoomPlugin(rooms.currentRoom);
	}

	async function onScrollToEventId(ev: any) {
		// if there is a threadId and this is a valid id in the room: set the current threadId
		if (ev.threadId && ev.threadId !== ev.eventId) {
			if (!room.value.findEventById(ev.threadId)) {
				try {
					await room.value.loadToEvent(ev.threadId);
				} catch (e) {
					LOGGER.error(SMI.ROOM_TIMELINE, `Failed to load event ${eventId}`);
				}
			}
			room.value.setCurrentThreadId(ev.threadId);
		} else {
			room.value.setCurrentThreadId(undefined);
		}
		room.value.setCurrentEventId(ev.eventId);
	}

	async function stewardCanEdit() {
		currentRoomToEdit.value = await rooms.getTPublicOrTSecuredRoom(props.id);
		const isSecuredRoom = rooms.roomIsSecure(props.id);
		if (isSecuredRoom) secured.value = true;
		showEditRoom.value = true;
	}

	function closeEdit() {
		showEditRoom.value = false;
		secured.value = false;
	}

return (_ctx: any,_cache: any) => {
  const _component_EditRoomForm = _resolveComponent("EditRoomForm")!

  return (_unref(rooms).currentRoomExists)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (plugin.value === false)
          ? (_openBlock(), _createBlock(HeaderFooter, {
              key: 0,
              headerSize: 'sm',
              headerMobilePadding: true,
              bgBarLow: "bg-background",
              bgBarMedium: "bg-surface-low"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["items-center gap-4 text-on-surface-dim", isMobile.value ? 'hidden' : 'flex'])
                }, [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('rooms.room')), 1),
                  _cache[4] || (_cache[4] = _createElementVNode("hr", { class: "h-[2px] grow bg-on-surface-dim" }, null, -1))
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["relative flex h-full items-center justify-between gap-4", isMobile.value ? 'pl-12' : 'pl-0'])
                }, [
                  (_unref(rooms).currentRoom)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(Icon, {
                          type: _unref(rooms).currentRoom.isSecuredRoom() ? 'shield' : 'speech_bubbles',
                          size: "base"
                        }, null, 8, ["type"]),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(H3, { class: "flex text-on-surface" }, {
                            default: _withCtx(() => [
                              _createVNode(TruncatedText, { class: "font-headings font-semibold" }, {
                                default: _withCtx(() => [
                                  (_unref(rooms).currentRoom.isPrivateRoom())
                                    ? (_openBlock(), _createBlock(PrivateRoomName, {
                                        key: 0,
                                        members: _unref(rooms).currentRoom.getOtherJoinedAndInvitedMembers()
                                      }, null, 8, ["members"]))
                                    : (_openBlock(), _createBlock(RoomName, {
                                        key: 1,
                                        room: _unref(rooms).currentRoom
                                      }, null, 8, ["room"]))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(TruncatedText, { class: "hidden md:inline" })
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_4, [
                    (room.value.getUserPowerLevel(_unref(user).user.userId) === 50)
                      ? (_openBlock(), _createBlock(GlobalBarButton, {
                          key: 0,
                          type: "cog",
                          size: "sm",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (stewardCanEdit()))
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(SearchInput, {
                      "search-parameters": searchParameters.value,
                      onScrollToEventId: onScrollToEventId,
                      room: _unref(rooms).currentRoom
                    }, null, 8, ["search-parameters", "room"])
                  ])
                ], 2)
              ]),
              footer: _withCtx(() => [
                (showEditRoom.value)
                  ? (_openBlock(), _createBlock(_component_EditRoomForm, {
                      key: 0,
                      room: currentRoomToEdit.value,
                      secured: secured.value,
                      onClose: _cache[3] || (_cache[3] = ($event: any) => (closeEdit()))
                    }, null, 8, ["room", "secured"]))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    (room.value)
                      ? (_openBlock(), _createBlock(RoomTimeline, {
                          key: 0,
                          room: room.value,
                          "scroll-to-event-id": room.value.getCurrentEventId(),
                          onScrolledToEventId: _cache[1] || (_cache[1] = ($event: any) => (room.value.setCurrentEventId(undefined)))
                        }, null, 8, ["room", "scroll-to-event-id"]))
                      : _createCommentVNode("", true)
                  ]),
                  (room.value.getCurrentThreadId())
                    ? (_openBlock(), _createBlock(RoomThread, {
                        key: 0,
                        room: room.value,
                        "scroll-to-event-id": room.value.getCurrentEventId(),
                        onScrolledToEventId: _cache[2] || (_cache[2] = ($event: any) => (room.value.setCurrentEventId(undefined))),
                        onThreadLengthChanged: currentThreadLengthChanged
                      }, null, 8, ["room", "scroll-to-event-id"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (plugin.value !== false && plugin.value !== true)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(plugin.value.component), { key: 1 }))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}
}

})