<template>
	<div class="flex w-full flex-col">
		<div class="flex w-full items-center gap-4 pb-4">
			<Icon type="pubhubs-home" class="text-surface-high dark:text-on-surface-dim" />
			<TextInput v-if="!listTop" :placeholder="placeholder" v-model="filter" class="h-8 w-full border-none !bg-surface-low ~text-label-min/label-max" :class="inputClass" @input="changed()" />
		</div>
		<ul v-if="filteredItems.length > 0" :class="listClass + ' flex h-full flex-col gap-2 overflow-y-auto overflow-x-hidden rounded-md'">
			<li v-for="(item, index) in filteredItems" :key="index" class="group block" @click="clickedItem(item)">
				<slot name="item" v-bind="{ item }"></slot>
			</li>
		</ul>
	</div>
</template>

<script setup lang="ts">
	import { ref, computed } from 'vue';
	import { FilteredListEvent } from '@/model/components/FilteredListEvent';

	const emit = defineEmits(['click', 'filter']);

	const filter = ref('');

	type Props = {
		items: Array<Record<string, any>>;
		filterKey?: string[];
		minLength?: number;
		listTop?: boolean;
		showCompleteList?: boolean;
		inputClass?: string;
		listClass?: string;
		sortby: string;
		placeholder: string;
	};

	const props = withDefaults(defineProps<Props>(), {
		items: () => [],
		filterKey: () => ['name'],
		sortby: '',
		placeholder: 'Filter',
		inputClass: '',
		listClass: '',
		minLength: 1,
		listTop: false,
		showCompleteList: true,
	});

	const filteredItems = computed(() => {
		let items = props.items;
		if (filter.value.length >= props.minLength) {
			const lcFilter = filter.value.toLowerCase();
			items = props.items.filter((item: any) => {
				if (filter.value === '') {
					return true;
				}
				for (const filterKey of props.filterKey) {
					const lcItem = item[filterKey]?.toLowerCase();
					if (lcItem && lcItem.includes(lcFilter)) return true;
				}
				return false;
			});
			if (props.sortby !== '') {
				items = items.toSorted((a: Record<string, any>, b: Record<string, any>) => {
					return a[props.sortby].toLowerCase().localeCompare(b[props.sortby].toLowerCase());
				});
			}
		} else {
			if (!props.showCompleteList) {
				items = [];
			}
		}
		return items;
	});

	function changed() {
		filter.value = filter.value.toLocaleLowerCase();
		const event: FilteredListEvent = {
			filter: filter.value,
			length: filteredItems.value.length,
		};
		emit('filter', event);
	}

	function clickedItem(item: any) {
		filter.value = '';
		emit('click', item);
	}
</script>
