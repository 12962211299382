import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute top-16 flex flex-col text-center" }
const _hoisted_2 = { class: "z-20 box-border min-h-[333px] w-[255px] rounded-lg bg-white p-0 drop-shadow-[0px_-5px_16px_rgb(0,0,0,0.15)]" }
const _hoisted_3 = {
  key: 0,
  class: "mx-4 my-12 flex flex-col gap-12 after:absolute after:-top-[1.2em] after:left-[50%] after:border-[1.25em] after:border-r-0 after:border-t-0 after:border-transparent after:border-b-white after:drop-shadow-[0px_-5px_16px_rgb(0,0,0,0.15)]"
}
const _hoisted_4 = { class: "flex flex-row items-center" }
const _hoisted_5 = { class: "ml-2 text-left ~text-label-min/label-max dark:text-black" }
const _hoisted_6 = ["title"]
const _hoisted_7 = {
  key: 2,
  class: "z-5 absolute inset-0 rounded-lg bg-white"
}
const _hoisted_8 = { class: "my-24 flex flex-col items-center justify-center gap-4" }
const _hoisted_9 = { class: "~text-body-min/body-max ml-2 text-center dark:text-black" }

import { onMounted, ref } from 'vue';

	import QRCode from './SecuredRoomQR.vue';

	import { useI18n } from 'vue-i18n';
	import { useRooms } from '@/logic/store/rooms';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SecuredRoomLogin',
  props: {
    securedRoomId: {}
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

	const rooms = useRooms();

	const { t } = useI18n();
	const emit = __emit;

	const loginFlow = ref(false);
	const loginFail = ref(false);

	const props = __props;

	onMounted(async () => await rooms.getSecuredRoomInfo(props.securedRoomId));

	function showQR() {
		loginFlow.value = true;
	}

	function closePopOver() {
		emit('click');
	}
	function retry() {
		loginFail.value = false;
		loginFlow.value = false;
	}

	function loginError(error: string) {
		if (error) loginFail.value = true;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        type: "closingCross",
        size: "base",
        asButton: true,
        class: "absolute right-2 top-2 z-10 p-2 dark:text-black",
        onClick: closePopOver
      }),
      (!loginFlow.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Icon, {
                type: "lock",
                size: "md"
              }),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)('rooms.secure_room_message')), 1)
            ]),
            _createElementVNode("p", {
              class: "~text-body-min/body-max text-gray dark:text-gray-middle max-h-24 w-full overflow-y-auto",
              title: _unref(rooms).securedRoom.user_txt
            }, _toDisplayString(_unref(rooms).securedRoom.user_txt), 9, _hoisted_6),
            _createVNode(_component_Button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (showQR())),
              size: "sm"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('rooms.display_qr')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (loginFlow.value)
        ? (_openBlock(), _createBlock(QRCode, {
            key: 1,
            securedRoomId: _ctx.securedRoomId,
            onError: loginError
          }, null, 8, ["securedRoomId"]))
        : _createCommentVNode("", true),
      (loginFail.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Icon, {
                class: "text-avatar-red",
                type: "slash",
                size: "xl"
              }),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)('rooms.incorrect_attributes')), 1),
              _createVNode(_component_Button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (retry())),
                size: "sm"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('rooms.retry')), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})