import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vShow as _vShow, isRef as _isRef, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex aspect-square h-full w-full items-center justify-center overflow-hidden rounded-xl" }
const _hoisted_2 = ["src", "alt"]

import { computed, onMounted, ref } from 'vue';

	type Props = {
		hubNameForImgAlt?: string; // Used when showing multiple Hubs.
		iconUrl: string;
		iconUrlDark: string;
		isActive?: boolean;
	};
	
export default /*@__PURE__*/_defineComponent({
  __name: 'HubIcon',
  props: {
    hubNameForImgAlt: {},
    iconUrl: {},
    iconUrlDark: {},
    isActive: { type: Boolean }
  },
  setup(__props: any) {

	const props = __props;

	let fallback = ref(false);
	let imageLoaded = ref(false);

	const url = computed(getUrl);

	onMounted(() => {
		// Prevent the fallback quickly showing before the image is loaded
		setTimeout(showFallback, 500);
	});

	function showFallback() {
		if (!imageLoaded.value) {
			fallback.value = true;
		}
	}

	function getUrl(): string {
		return props.iconUrl;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("figure", _hoisted_1, [
    _withDirectives(_createElementVNode("img", {
      src: url.value,
      onLoad: _cache[0] || (_cache[0] = ($event: any) => (_isRef(imageLoaded) //@ts-ignore
 ? imageLoaded.value = true : imageLoaded = true)),
      alt: `Icon of Hub ${_ctx.hubNameForImgAlt ?? ''}`,
      class: "h-full w-full bg-on-accent-primary object-cover"
    }, null, 40, _hoisted_2), [
      [_vShow, _unref(imageLoaded)]
    ]),
    (_unref(fallback) && !_unref(imageLoaded))
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          type: "hub_fallback",
          class: "h-full w-full text-on-surface-variant"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})