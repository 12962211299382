import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, Suspense as _Suspense } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-wrap items-center overflow-hidden text-wrap break-all" }
const _hoisted_3 = { class: "relative flex min-h-6 w-full items-start gap-x-2 pb-1" }
const _hoisted_4 = { class: "flex w-full min-w-0 flex-grow flex-wrap items-center gap-2" }
const _hoisted_5 = { class: "flex gap-2" }
const _hoisted_6 = ["title"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]
const _hoisted_11 = { class: "flex items-center gap-3 rounded-md px-2" }

import { usePubHubs } from '@/logic/core/pubhubsStore';
	import { router } from '@/logic/core/router';
	import { TMessageEvent } from '@/model/events/TMessageEvent';
	import Room from '@/model/rooms/Room';
	import { useConnection } from '@/logic/store/connection';
	import { useMessageActions } from '@/logic/store/message-actions';
	import { PluginType } from '@/logic/store/plugins';
	import { RoomType } from '@/logic/store/rooms';
	import { FeatureFlag, useSettings } from '@/logic/store/settings';
	import { useUser } from '@/logic/store/user';
	import { computed, ref, watch, onMounted } from 'vue';
	import { PubHubsMgType } from '@/logic/core/events';

	// Components
	import MessageSnippet from './MessageSnippet.vue';
	import Message from './Message.vue';
	import MessageFile from './MessageFile.vue';
	import MessageImage from './MessageImage.vue';
	import MessageSigned from './MessageSigned.vue';
	import RoomEventActionsPopup from './RoomEventActionsPopup.vue';
	import Avatar from '../ui/Avatar.vue';
	import EventTime from './EventTime.vue';
	import ProfileAttributes from './ProfileAttributes.vue';
	import UserDisplayName from './UserDisplayName.vue';
	import Icon from '../elements/Icon.vue';
	import { MsgType } from 'matrix-js-sdk';
	import { RelationType } from '@/model/constants';

	// Stores
	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomEvent',
  props: {
		event: {
			type: Object as () => TMessageEvent,
			required: true,
		},
		eventThreadLength: {
			type: Number,
			default: 0,
		},
		room: {
			type: Room,
			required: true,
		},
		viewFromThread: {
			type: Boolean,
			default: false,
		},
		deleteMessageDialog: {
			type: Boolean,
			default: false,
		},
	},
  emits: ["inReplyToClick", "deleteMessage"],
  setup(__props, { emit: __emit }) {

	const connection = useConnection();
	const messageActions = useMessageActions();
	const user = useUser();
	const settings = useSettings();
	let threadLength = ref(0);

	const props = __props;

	onMounted(() => {
		// set initial threadlength
		threadLength.value = props.eventThreadLength;
	});

	/**
	 * Watch the threadlength for external changes, done by other users
	 */
	watch(
		() => props.eventThreadLength,
		() => {
			threadLength.value = props.eventThreadLength;
		},
	);

	const roomMember = props.room.getMember(props.event.sender, true);
	if (!roomMember) throw new Error('Sender of event not found while trying to display event.');

	const inReplyToId = props.event.content[RelationType.RelatesTo]?.[RelationType.InReplyTo]?.event_id;

	const emit = __emit;

	const msgIsNotSend = computed(() => {
		return props.event.event_id.substring(0, 1) === '~';
	});

	const isThreadRoot = computed(() => {
		return props.room.currentThread?.threadId === props.event.event_id;
	});

	const containsRedactedBecause = props.event.unsigned?.redacted_because !== undefined;

	const redactedMessage = computed(() => {
		const inRedactedMessageIds = props.room.inRedactedMessageIds(props.event.event_id);
		// Remove the event id from the list with redacted event IDs if the event already contains the redacted_because key
		if (inRedactedMessageIds && containsRedactedBecause) {
			props.room.removeRedactedEventId(props.event.event_id);
		}
		return inRedactedMessageIds || containsRedactedBecause;
	});

	const canReplyInThread = computed(() => {
		return !props.event.content[RelationType.RelatesTo];
	});

	/**
	 * Returns boolean whether the reply snippet can be shown
	 * When in a thread: images and files always get an reply, so it is not clear if they are meant to be a reply.
	 * Like Element we remove the replysnippet in that case.
	 */
	function showReplySnippet(msgType: string): boolean {
		if (props.viewFromThread) {
			if (msgType === MsgType.Image || msgType === MsgType.File) {
				return false;
			}
		}
		return !!inReplyToId && !redactedMessage.value;
	}

	function onInReplyToClick() {
		if (!inReplyToId) return;
		emit('inReplyToClick', inReplyToId);
	}

	function onDeleteMessage(event: TMessageEvent) {
		emit('deleteMessage', event);
	}

	function reply() {
		messageActions.replyingTo = props.event.event_id;
	}

	function replyInThread() {
		props.room.setCurrentThreadId(props.event.event_id);
	}

	function resend() {
		const pubhubs = usePubHubs();
		pubhubs.resendEvent(props.event);
	}

	// Waits for checking if message is realy send. Otherwise a 'resend' button appears. See also msgIsNotSend computed.
	const timerReady = ref(false);
	window.setTimeout(() => {
		timerReady.value = true;
	}, 1000);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (__props.event.plugin && __props.event.plugin.plugintype === _unref(PluginType).EVENT && __props.event.type === __props.event.plugin.type)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(__props.event.plugin.component), { event: __props.event }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.event.plugin.component), 1)
            ]),
            _: 1
          }, 8, ["event"]))
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["group flex gap-6 px-3 py-4 md:px-6", { 'transition-all duration-150 ease-in-out hover:bg-surface-low': !__props.deleteMessageDialog, 'mx-4 rounded bg-surface shadow-[0_0_5px_0_rgba(0,0,0,0.3)]': props.deleteMessageDialog }])
        }, [
          _createVNode(Avatar, { user: _unref(roomMember) }, null, 8, ["user"]),
          _createElementVNode("div", {
            class: _normalizeClass([{ 'w-5/6': __props.deleteMessageDialog, 'w-full': !__props.deleteMessageDialog }, "min-w-0"])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(UserDisplayName, {
                    user: __props.event.sender,
                    room: __props.room
                  }, null, 8, ["user", "room"]),
                  _createElementVNode("span", _hoisted_5, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "~text-label-small-min/label-small-max" }, "|", -1)),
                    _createVNode(EventTime, {
                      timestamp: __props.event.origin_server_ts,
                      showDate: false
                    }, null, 8, ["timestamp"]),
                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "~text-label-small-min/label-small-max" }, "|", -1)),
                    _createVNode(EventTime, {
                      timestamp: __props.event.origin_server_ts,
                      showDate: true
                    }, null, 8, ["timestamp"])
                  ]),
                  (props.room.getType() == _unref(RoomType).PH_MESSAGES_RESTRICTED)
                    ? (_openBlock(), _createBlock(ProfileAttributes, {
                        key: 0,
                        class: "flex-1",
                        user: __props.event.sender,
                        room_id: __props.event.room_id
                      }, null, 8, ["user", "room_id"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  (timerReady.value && !__props.deleteMessageDialog)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (msgIsNotSend.value && _unref(connection).isOn)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (resend())),
                              class: "mb-1 ml-2",
                              title: _ctx.$t('errors.resend')
                            }, [
                              _createVNode(Icon, {
                                type: "refresh",
                                size: "sm",
                                class: "text-red"
                              })
                            ], 8, _hoisted_6))
                          : _createCommentVNode("", true),
                        (msgIsNotSend.value && !_unref(connection).isOn)
                          ? (_openBlock(), _createBlock(Icon, {
                              key: 1,
                              type: "lost-connection",
                              size: "sm",
                              class: "text-red mb-1 ml-2"
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (!__props.deleteMessageDialog)
                    ? (_openBlock(), _createBlock(RoomEventActionsPopup, { key: 1 }, {
                        default: _withCtx(() => [
                          (!msgIsNotSend.value && !redactedMessage.value && !isThreadRoot.value)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                onClick: reply,
                                class: "flex items-center justify-center rounded-md p-1 text-on-surface-variant transition-all duration-300 ease-in-out hover:w-fit hover:bg-accent-primary hover:text-on-accent-primary",
                                title: _ctx.$t('message.reply')
                              }, [
                                _createVNode(Icon, {
                                  type: 'reply',
                                  size: "xs"
                                })
                              ], 8, _hoisted_7))
                            : _createCommentVNode("", true),
                          (!__props.viewFromThread && _unref(threadLength) <= 0 && canReplyInThread.value && !msgIsNotSend.value && !redactedMessage.value)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 1,
                                onClick: replyInThread,
                                class: "flex items-center justify-center rounded-md p-1 text-on-surface-variant transition-all duration-300 ease-in-out hover:w-fit hover:bg-accent-primary hover:text-on-accent-primary",
                                title: _ctx.$t('message.reply_in_thread')
                              }, [
                                _createVNode(Icon, {
                                  type: 'talk',
                                  size: 'xs'
                                })
                              ], 8, _hoisted_8))
                            : _createCommentVNode("", true),
                          (!msgIsNotSend.value && _unref(user).isAdmin && __props.event.sender !== _unref(user).user.userId && _unref(settings).isFeatureEnabled(_unref(FeatureFlag).disclosure))
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 2,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({ name: 'ask-disclosure', query: { user: __props.event.sender } }))),
                                class: "flex items-center justify-center rounded-md p-1 text-on-surface-variant transition-all duration-300 ease-in-out hover:w-fit hover:bg-accent-primary hover:text-on-accent-primary",
                                title: _ctx.$t('menu.moderation_tools_disclosure')
                              }, [
                                _createVNode(Icon, {
                                  type: 'warning',
                                  size: "xs"
                                })
                              ], 8, _hoisted_9))
                            : _createCommentVNode("", true),
                          (_unref(settings).isFeatureEnabled(_unref(FeatureFlag).deleteMessages) && !msgIsNotSend.value && __props.event.sender === _unref(user).user.userId && !redactedMessage.value)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 3,
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (onDeleteMessage(__props.event))),
                                class: "flex items-center justify-center rounded-md p-1 text-on-surface-variant transition-all duration-300 ease-in-out hover:w-fit hover:bg-accent-red hover:text-on-accent-red",
                                title: _ctx.$t('menu.delete_message')
                              }, [
                                _createVNode(Icon, {
                                  type: 'bin',
                                  size: "xs"
                                })
                              ], 8, _hoisted_10))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            (__props.event.plugin?.plugintype === _unref(PluginType).MESSAGE && __props.event.content.msgtype === __props.event.plugin.type)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(__props.event.plugin.component), {
                  key: 0,
                  event: __props.event
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(__props.event.plugin.component), 1)
                  ]),
                  _: 1
                }, 8, ["event"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    fallback: _withCtx(() => [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t('state.loading_message')), 1)
                      ])
                    ]),
                    default: _withCtx(() => [
                      (showReplySnippet(__props.event.content.msgtype))
                        ? (_openBlock(), _createBlock(MessageSnippet, {
                            key: 0,
                            class: _normalizeClass({ '!bg-[#e2e2e2]': __props.deleteMessageDialog }),
                            onClick: onInReplyToClick,
                            eventId: _unref(inReplyToId),
                            showInReplyTo: true,
                            room: __props.room
                          }, null, 8, ["class", "eventId", "room"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })),
                  (__props.event.content.msgtype === _unref(MsgType).Text || redactedMessage.value)
                    ? (_openBlock(), _createBlock(Message, {
                        key: 0,
                        event: __props.event,
                        deleted: redactedMessage.value,
                        class: "max-w-[90ch]"
                      }, null, 8, ["event", "deleted"]))
                    : _createCommentVNode("", true),
                  (__props.event.content.msgtype === _unref(PubHubsMgType).SignedMessage && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(MessageSigned, {
                        key: 1,
                        class: _normalizeClass([{ '!bg-[#e2e2e2]': __props.deleteMessageDialog }, "max-w-[90ch]"]),
                        message: __props.event.content.signed_message
                      }, null, 8, ["class", "message"]))
                    : _createCommentVNode("", true),
                  (__props.event.content.msgtype === _unref(MsgType).File && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(MessageFile, {
                        key: 2,
                        message: __props.event.content,
                        class: "max-w-[90ch]"
                      }, null, 8, ["message"]))
                    : _createCommentVNode("", true),
                  (__props.event.content.msgtype === _unref(MsgType).Image && !redactedMessage.value)
                    ? (_openBlock(), _createBlock(MessageImage, {
                        key: 3,
                        message: __props.event.content,
                        class: "max-w-[90ch]"
                      }, null, 8, ["message"]))
                    : _createCommentVNode("", true)
                ], 64)),
            (!__props.deleteMessageDialog && !__props.viewFromThread && _unref(threadLength) > 0 && canReplyInThread.value && !msgIsNotSend.value && !redactedMessage.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  onClick: replyInThread,
                  class: "inline-flex items-center rounded-md bg-surface-high px-2 py-1 ~text-label-tiny-min/label-tiny-max hover:bg-accent-primary hover:text-on-accent-primary"
                }, [
                  _createVNode(Icon, {
                    type: 'talk',
                    size: 'xs'
                  }),
                  _createTextVNode("   " + _toDisplayString(_ctx.$t('message.threads.view_thread')) + " (" + _toDisplayString(_unref(threadLength)) + ") ", 1)
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ], 2))
  ]))
}
}

})